import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import {
  BsCloudUpload,
  BsCheckLg,
  BsExclamationTriangle,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';

import { accessTokenHeader } from '../../Utils';
import shotIcon from '../../assets/clips_shot.png';
import landscapeImg from '../../assets/landscape.png';
import ContentFooter from '../../components/contentFooter';
import ContentHeader from '../../components/contentHeader';
import GeneratedVideosAlert from '../../components/generatedVideosAlert';
import ProjectBuilderStepper from '../../components/projectBuilderStepper';
import SideNav from '../../components/sideNav/sideNav';
import ZipModal from '../../components/zipModal';

import config from '../../config';
import { uploadFileWithInfo } from '../../services/uploadFileService';

const { apiBaseUrl } = config;

const currentWorkspace = JSON.parse(localStorage.getItem('currentWorkspace'));
const workspaceId = currentWorkspace?.workspaceId || config.workspaceId;

const VideoUpload = (props, args) => {
  const { isSideNav } = props;

  const templateStorageData = localStorage.getItem('templateStorageData');
  const storedUploadedStatus = localStorage.getItem('videoIsUploaded');

  const storedTemplateShot =
    templateStorageData && templateStorageData.trim() !== ''
      ? JSON.parse(templateStorageData).shots
      : null;

  // for video process logic
  const [loadingError, setLoadingError] = useState(false);
  const [fetchDone, setFetchDone] = useState(
    (storedTemplateShot && storedTemplateShot.length !== 0) || false,
  );
  const [templateData, setTemplateData] = useState(storedTemplateShot || null);
  const [clickedShot, setClickedShot] = useState(null);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [showZipModal, setShowZipModal] = useState(false);

  const navigate = useNavigate();
  const [isUploaded, setIsUploaded] = useState(
    (storedUploadedStatus && storedUploadedStatus === 'true') ||
      (storedTemplateShot && storedTemplateShot.length !== 0) ||
      false,
  );

  useEffect(() => {
    if (!storedTemplateShot) {
      fetchTemplateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTemplateData = () => {
    function fetchData() {
      fetch(
        `${apiBaseUrl.elements}/api/video-template/current?workspaceId=${workspaceId}`,
        {
          method: 'GET',
          ...accessTokenHeader,
        },
      )
        .then(async (response) => {
          if (response.status === 404) {
            console.log('Template not found');
            return null;
          } else {
            setIsUploaded(true);
            return response.json();
          }
        })
        .then((data) => {
          if (data) {
            console.log(data);
            if (data.videoTemplate !== null) {
              setIsUploaded(true);
              console.log('get data success!');

              if (data.videoTemplate.shots.length > 0) {
                const shots = data.videoTemplate.shots;
                const someShotsHaveFrameId = shots.some((shot) =>
                  shot.hasOwnProperty('frameId'),
                );
                if (someShotsHaveFrameId) {
                  console.log('frameId fetched!');
                  localStorage.setItem(
                    'templateStorageData',
                    JSON.stringify(data.videoTemplate),
                  );
                  setFetchDone(true);
                  setTemplateData(shots);
                } else {
                  console.log(
                    'Some shots are missing frameId, fetching again...',
                  );
                  setTimeout(fetchData, 10000);
                  setTemplateData(shots);
                }
              } else {
                setTimeout(fetchData, 10000);
              }
            } else {
              console.log('fetching...');
              setTimeout(fetchData, 10000);
            }
          }
        })
        .catch((err) => {
          console.log(`get template thumbnail error: ${err}`);
          setLoadingError(true);
        });
    }
    // Start fetching
    fetchData();
  };

  const processSubtitle = () => {
    if (!fetchDone && isUploaded) {
      return 'First we have to turn your raw video file into a template for later use, this just takes a few moments...';
    }
    if (fetchDone) {
      return 'Before continuing, please approve the below as your intended template.';
    }
  };

  const { Dragger } = Upload;
  const draggerProps = {
    name: 'file',
    accept: 'video/*',
    listType: 'picture',
    maxCount: 1,
    onChange(info) {
      // console.log(info);
      const { status } = info.file;
      setFetchDone(false);
      if (status === 'uploading') {
        // console.log(info.file, info.fileList);
        localStorage.setItem('videoIsUploaded', 'true');
      }
      if (status === 'done') {
        localStorage.setItem('videoIsUploaded', 'true');
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        toast.error(`${info.file.name} file upload failed.`);
      }
      if (info.fileList.length === 0) {
        setIsUploaded(false);
        localStorage.removeItem('videoIsUploaded');
      }
    },
    onRemove() {
      setFetchDone(false);
      setIsUploaded(false);
      setLoadingError(false);
      localStorage.removeItem('videoIsUploaded');
    },
  };

  const customRequest = (info) => {
    console.log(info);
    // mergefile and upload file
    uploadFileWithInfo({
      file: info.file,
      workspaceId,
      info: info,
      fileKind: 'video-template',
    })
      .then(() => {
        // get template thumbnail
        fetchTemplateData();
        setIsUploaded(true);
      })
      .catch((err) => {
        console.log(err);
        setLoadingError(true);
      });
  };

  const handleClickThumbnail = (frameId) => {
    setClickedShot(frameId);
    toggleModal();
  };

  const ImgModal = (frameId) => {
    return (
      <Modal isOpen={modal} toggle={toggleModal} {...args} size="lg" centered>
        <ModalHeader toggle={toggleModal}>Template Shot Preview</ModalHeader>
        <ModalBody className="mx-auto">
          <img
            src={`https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.thumbnails/${frameId.shot}/720x405.png`}
            alt="Shot Thumbnail"
            className="thumbnail-preview"
          />
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  };

  return (
    <div className="page-container with-side-nav">
      {isSideNav && <SideNav />}

      <ProjectBuilderStepper activeStep={0} />

      <ContentHeader
        title="Upload your video template"
        subtitle="To start, upload the video file to use as your template. This should be an mp4, in 1080p resolution ideally under 1 minute long."
        onZipClick={() => setShowZipModal(true)}
      />

      <div className="page-content">
        <GeneratedVideosAlert alertText="If you upload a new template, it will only be used for faces added after. All generated videos will remain the same." />

        {!isUploaded ? (
          <Dragger
            {...draggerProps}
            customRequest={(info) => customRequest(info)}
          >
            <BsCloudUpload className="upload-icon" size={80} />
            <div>Drag and drop or click to upload</div>
          </Dragger>
        ) : (
          <Dragger
            {...draggerProps}
            className="shrunk success"
            customRequest={(info) => customRequest(info)}
          >
            <BsCheckLg size={40} />
            <div>Thanks, you have uploaded your template successfully!</div>
          </Dragger>
        )}

        {isUploaded && !loadingError && (
          <Alert color="danger">{processSubtitle()}</Alert>
        )}

        {loadingError && !fetchDone && (
          <Alert color="warning" className="text-with-icon big-icon">
            <BsExclamationTriangle />
            Unfortunately, there was an error when processing your video upload.
            Please try again later.
          </Alert>
        )}

        {isUploaded && !loadingError && (
          <div className="template-preview">
            {!fetchDone ? (
              <div className="thumbanails-container">
                {Array.from({ length: 10 }).map((item, index) => (
                  <div key={index} className="thumbanail-block">
                    <div className="img-overlay">
                      <Spinner animation="border" role="status" />
                    </div>
                    <img
                      src={landscapeImg}
                      className="thumbnail"
                      alt="Shot Thumbnail"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="thumbanails-container">
                {templateData.map((shot, index) => (
                  <div key={index} className="thumbanail-block">
                    {shot.frameId ? (
                      <img
                        src={`https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.thumbnails/${shot.frameId}/720x405.png`}
                        className="thumbnail enlarge"
                        alt="Shot Thumbnail"
                        onClick={() => handleClickThumbnail(shot.frameId)}
                      />
                    ) : (
                      <img
                        src={shotIcon}
                        className="thumbnail"
                        alt="Shot Placeholder"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="gradient-effect" />
          </div>
        )}
      </div>

      <ContentFooter
        onClickContinue={() => navigate('/upload-faces')}
        allowContinue={isUploaded}
        popoverHeader="Please wait"
        popoverBody="The template is not uploaded yet"
      />

      <ZipModal
        show={showZipModal}
        onHide={() => setShowZipModal(false)}
        type="Template"
      />

      <ImgModal shot={clickedShot} />
    </div>
  );
};

export default VideoUpload;
