import config from '../../config';
import { getAccessTokenClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);

export const getFootageFiles = async (workspaceId) => {
  try {
    const response = await accessTokenClient.get(
      `api/files?workspaceId=${workspaceId}&fileType=video&fileKind=VideoFootage`,
    );
    return response.data.files;
  } catch (err) {
    throw new Error(`Error getting footage files: ${err.message}`);
  }
};

export const getClipResults = async (workspaceId) => {
  try {
    const response = await accessTokenClient.get(
      `api/clip-results?workspaceId=${workspaceId}`,
    );
    return response.data;
  } catch (err) {
    throw new Error(`Error getting clip results: ${err.message}`);
  }
};
