import React from 'react';
import parse from 'html-react-parser';
import { Alert } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';

const GeneratedVideosAlert = (props) => {
  const { alertText } = props;

  const generationStatus = localStorage.getItem('generationStatus');

  if (!generationStatus) return null;

  return (
    <Alert key="warning" variant="warning">
      <Alert.Heading className="text-with-icon">
        <BsExclamationTriangle />
        <div>Videos have already been generated</div>
      </Alert.Heading>

      <div>{parse(alertText)}</div>
    </Alert>
  );
};

export default GeneratedVideosAlert;
