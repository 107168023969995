import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl, media: mediaUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);
const mediaAccessTokenClient = getAccessTokenClient(mediaUrl);
const apiKeyClient = getApiKeyClient(baseUrl);

export const privateFaceCreate = async (name, workspaceId) => {
  try {
    const response = await accessTokenClient.put('api/face', {
      name,
      workspaceId,
    });
    console.log('create face success!', response);
    return response.data.face.elementId;
  } catch (err) {
    throw new Error(`Error with creating face: ${err.message}`);
  }
};

export const publicFaceCreate = async (name, workspaceId) => {
  try {
    const response = await apiKeyClient.put('api/face', {
      name,
      workspaceId,
    });
    console.log('create face success!', response);
    return response.data.face.elementId;
  } catch (err) {
    throw new Error(`Error with creating face: ${err.message}`);
  }
};

export const indexFaces = (parentElementId) => {
  return mediaAccessTokenClient
    .get(`api/face/index?elementId=${parentElementId}`)
    .then((data) => {
      console.log('index face success!', data);
    })
    .catch((err) => {
      console.log(`index face error: ${err}`);
    });
};

export const getUploadedFaces = (workspaceId) => {
  return accessTokenClient
    .get(`api/faces?workspaceId=${workspaceId}`)
    .then((res) => {
      console.log('get face names success!', res);
      return res.data.faces;
    })
    .catch((err) => {
      throw new Error('Error fetching uploaded face names', err);
    });
};

export const getFaceResults = async (workspaceId) => {
  try {
    const response = await accessTokenClient.get(
      `api/face-results?workspaceId=${workspaceId}`,
    );
    return response.data;
  } catch (err) {
    throw new Error(
      `Error getting face results: ${err.response.data.message || err.message}`,
    );
  }
};
