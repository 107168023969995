import React from 'react';
import { Upload } from 'antd';
import { BsCloudUpload } from 'react-icons/bs';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from '../config';
import { FOOTAGE_UPLOAD_LIMIT, MAX_FILE_SIZE_MB } from '../constants';
import { uploadFile } from '../services/uploadFileService';

const currentWorkspace = JSON.parse(localStorage.getItem('currentWorkspace'));
const workspaceId = currentWorkspace?.workspaceId || config.workspaceId;

class FileDropArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalContent: null,
    };
  }

  toggleModal = () => {
    // Function to toggle the modal state
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  customRequest(info) {
    uploadFile({ file: info.file, workspaceId, fileKind: 'video-footage' });
    this.props.onDrop();
  }

  beforeUpload(file, fileList) {
    if (fileList.length > FOOTAGE_UPLOAD_LIMIT) {
      // Display an error message and show the modal
      console.error('Maximum file counts exceeded');
      this.setState({
        modal: true,
        modalContent: (
          <div>
            Maximum number of files exceeded! To optimize the uploading process,
            please only upload <b>{FOOTAGE_UPLOAD_LIMIT}</b> files at a time.
          </div>
        ),
      });
      return false;
    }
    if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
      // Check if file size exceeds the maximum allowed size
      console.error('Maximum file size exceeded');
      this.setState({
        modal: true,
        modalContent: (
          <div>
            File size exceeds the maximum limit of <b>{MAX_FILE_SIZE_MB}</b> MB!
            To optimize the uploading process, Please upload a smaller file.
          </div>
        ),
      });
      return false;
    }
    // Allow the file to be uploaded
    return true;
  }

  render() {
    const { type } = this.props;

    return (
      <>
        <Upload.Dragger
          multiple={true}
          accept="video/*"
          className={type}
          customRequest={(info) => this.customRequest(info)}
          beforeUpload={(file, fileList) => this.beforeUpload(file, fileList)}
          showUploadList={false}
        >
          <BsCloudUpload
            className="upload-icon"
            size={type === 'shrunk' ? 40 : 80}
          />
          <div>Drag and drop or click to upload</div>
        </Upload.Dragger>
        <Modal className="custom-modal" isOpen={this.state.modal} centered>
          <ModalHeader toggle={this.toggleModal}>Note</ModalHeader>
          <ModalBody className="text-center fs-5">
            {this.state.modalContent}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-primary" onClick={this.toggleModal}>
              Okay
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default FileDropArea;
