import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);
const apiKeyClient = getApiKeyClient(baseUrl);

export const getRequiredShots = async (workspaceId) => {
  try {
    const response = await accessTokenClient.get(
      `api/video-template/current?workspaceId=${workspaceId}`,
    );

    let requiredShots = [];
    response.data.videoTemplate.shots.forEach((shot) => {
      if (shot.type === 'placeholder')
        requiredShots.push(shot.segment.durationMilliseconds / 1000);
    });

    return { success: true, requiredShots };
  } catch (err) {
    if (err.response.status === 404)
      return { success: false, requiredShots: [] };
    else throw new Error(`Error getting a template: ${err.message}`);
  }
};
