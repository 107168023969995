import { getUserWorkspaces } from './api/workspaces';

export const changeWorkspace = async (workspaceId) => {
  // update user workspaces list
  const user = JSON.parse(localStorage.getItem('user'));
  const workspaces = await getUserWorkspaces(user.session.userId);

  // set current workspace
  const workspace = workspaces.find(
    (workspace) => workspace.workspaceId === workspaceId,
  );
  localStorage.setItem('currentWorkspace', JSON.stringify(workspace));

  // clear localStorage with previous workspace data (grouped)
  // general
  localStorage.removeItem('zipOption');
  // upload template
  localStorage.removeItem('templateStorageData');
  localStorage.removeItem('videoIsUploaded');
  // upload faces
  localStorage.removeItem('elementId');
  localStorage.removeItem('faceName');
  localStorage.removeItem('uploadedImages');
  // confirm faces
  localStorage.removeItem('completeFacesIds');
  // generate videos
  localStorage.removeItem('generationStatus');
};
