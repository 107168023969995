import React from 'react';
import { Form, InputGroup, FloatingLabel } from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../constants';

class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };

    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { name, label, value, isInvalid, errMsg, hideText, onChange } =
      this.props;
    const { showPassword } = this.state;

    return (
      <InputGroup className="password-input-group">
        <FloatingLabel label={label}>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            name={name}
            value={value}
            onChange={onChange}
            placeholder="******"
            isInvalid={isInvalid}
            required
          />
          {errMsg && (
            <Form.Control.Feedback type="invalid">
              {errMsg}
            </Form.Control.Feedback>
          )}
          {!hideText && (
            <Form.Text muted>
              Password must be {PASSWORD_MIN_LENGTH}-{PASSWORD_MAX_LENGTH}{' '}
              characters long, contain at least 1 lowercase character, 1
              uppercase character, 1 number, and 1 special character.
            </Form.Text>
          )}
        </FloatingLabel>
        <InputGroup.Text onClick={this.toggleShowPassword}>
          {showPassword ? (
            <BsFillEyeSlashFill size="1.5em" />
          ) : (
            <BsFillEyeFill size="1.5em" />
          )}
        </InputGroup.Text>
      </InputGroup>
    );
  }
}

export default PasswordField;
