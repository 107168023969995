import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

class EmailField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, value, isInvalid, errMsg, readOnly, disabled, onChange } =
      this.props;

    return (
      <FloatingLabel label="Email address">
        <Form.Control
          type="email"
          name={name || 'email'}
          value={value}
          onChange={onChange}
          placeholder="name@example.com"
          isInvalid={isInvalid}
          required
          readOnly={readOnly}
          disabled={disabled}
        />
        {errMsg && (
          <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
        )}
      </FloatingLabel>
    );
  }
}

export default EmailField;
