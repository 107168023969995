import React from 'react';
import { Modal } from 'react-bootstrap';
import { BsCloudDownload } from 'react-icons/bs';
import { isEmpty } from '../Utils.js';

import config from '../config';

const { zipUrl } = config;

const zipFilesDict = {
  Template: [
    { name: 'Wedding', link: zipUrl.weddingTemplateZip },
    { name: 'FIFA', link: zipUrl.fifaTemplateZip },
  ],
  Faces: [
    { name: 'Wedding', link: zipUrl.weddingFacesZip },
    { name: 'FIFA', link: zipUrl.fifaFacesZip },
  ],
  'Raw Footage': [
    { name: 'Wedding', link: zipUrl.weddingFootageZip },
    { name: 'FIFA', link: zipUrl.fifaFootageZip },
  ],
};

const ZipModal = (props) => {
  const { show, onHide, type } = props;

  //   get only zip files of required type
  const zipFiles = zipFilesDict[type];

  //   get zip option saved when one of zips chosen (most likely on template page)
  const zipOption = localStorage.getItem('zipOption');

  //   filter zip files to see if a zip option already selected
  let zipFilesFiltered = zipFiles.filter(
    (zipFile) => zipFile.name === zipOption,
  );
  let oneChoice = true;

  //   if no zip option matched or page is template – show all options
  if (isEmpty(zipFilesFiltered) || type === 'Template') {
    zipFilesFiltered = [...zipFiles];
    oneChoice = false;
  }

  // when zip selected – save option in localStorage and close modal
  const onSelectZip = (name) => {
    localStorage.setItem('zipOption', name);
    onHide();
  };

  return (
    <Modal
      className="custom-modal"
      show={show}
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Don't know what to upload?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>No worries, we have you covered!</div>

        <div>
          Download {oneChoice ? 'ZIP file below' : 'one of below ZIP files'}{' '}
          with sample data you can use on this page.
        </div>

        <div className="modal-btn-group">
          {zipFilesFiltered.map((zipFile) => {
            return (
              <a
                href={zipFile.link}
                download
                className={`btn btn-primary ${oneChoice ? 'full' : ''}`}
                onClick={() => onSelectZip(zipFile.name)}
              >
                <BsCloudDownload size="1.5em" /> Download {zipFile.name} {type}
              </a>
            );
          })}
        </div>

        <div className="note">
          Just click a button and ZIP file will start downloading.
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ZipModal;
