import React from 'react';
import { Link } from 'react-router-dom';

const SideNabBtn = (props) => {
  const { icon, text, link, extraIcon, isBtnImitator } = props;

  if (isBtnImitator)
    return (
      <div className="btn-imitator btn-clear">
        <div className="text-with-icon">
          {icon}
          <div>{text}</div>
        </div>
        {extraIcon}
      </div>
    );

  return (
    <Link to={link} className="btn btn-clear" target="_blank">
      <div className="text-with-icon">
        {icon}
        <div>{text}</div>
      </div>
      {extraIcon}
    </Link>
  );
};

export default SideNabBtn;
