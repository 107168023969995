import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BsList } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import Logo from './logo';

const Header = (props) => {
  const { onClickIcon } = props;

  const user = JSON.parse(localStorage.getItem('user'));
  const [isFreemium, setIsFreemium] = useState(user?.userType === 'freemium');

  const pathname = window.location.pathname;

  // if page is event check-in, or logged in user goes to not project builder pages – they only see logo
  // TODO: need to come up with better way to differenciate header later
  const hideUserEl =
    pathname.includes('/events/') ||
    (user &&
      ![
        '/workspace-details',
        '/video-upload',
        '/upload-faces',
        '/upload-footage',
        '/confirm-faces',
        '/generate-videos',
        '/analytics',
        '/brand',
      ].includes(pathname));

  const navigate = useNavigate();

  const toggleIsFreemium = () => {
    let temp = !isFreemium;

    setIsFreemium(temp);

    if (temp) user.userType = 'freemium';
    else user.userType = 'premium';

    localStorage.setItem('user', JSON.stringify(user));

    window.location.reload(false);
  };

  if (hideUserEl)
    return (
      <div className="header">
        <Logo isWhite={true} />
      </div>
    );
  else
    return (
      <div className="header">
        <div className="left-block">
          {user && (
            <Button variant="clear" className="menu-bar-icon">
              <BsList onClick={onClickIcon} />
            </Button>
          )}
          <Logo isWhite={true} />
        </div>

        <div className="right-block">
          {user ? (
            <Form.Check id="custom-switch" type="switch">
              <Form.Check.Input
                checked={isFreemium}
                onChange={toggleIsFreemium}
              />
              <Form.Check.Label>
                {isFreemium ? 'Freemium' : 'Premium'}
              </Form.Check.Label>
            </Form.Check>
          ) : (
            <Button onClick={() => navigate('/login')}>Log In</Button>
          )}
        </div>
      </div>
    );
};

export default Header;
