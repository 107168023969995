import React from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';

const AddFaceNameForm = ({
  face,
  handleOnChangeInput,
  handleSubmitName,
  isInvalid,
  submitButtonDisabled,
}) => {
  return (
    <Form className="inline-form" noValidate onSubmit={handleSubmitName}>
      <FloatingLabel label="Full name">
        <Form.Control
          type="text"
          name="name"
          value={face.name}
          onChange={handleOnChangeInput}
          placeholder="Please enter a full name"
          isInvalid={isInvalid}
          required
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid full name
        </Form.Control.Feedback>
      </FloatingLabel>

      <Button
        className="inline-btn"
        color="primary"
        type="submit"
        disabled={submitButtonDisabled}
      >
        Enter
      </Button>
    </Form>
  );
};

export default AddFaceNameForm;
