import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};
