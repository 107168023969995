import React from 'react';
import { Button, Spinner } from 'reactstrap';
import PopoverTrigger from './popoverTrigger';

const AddFaceButton = ({
  handleClickBtn,
  isCheckInEnabled,
  hasImage,
  showAddFaces,
  isSubmitting,
  elementId,
  buttonText,
  className,
}) => {
  return (
    <PopoverTrigger
      content={
        <div className="face-icon">
          <Button
            onClick={handleClickBtn}
            color="primary"
            className={`${className} fade-in`}
            disabled={
              (!isCheckInEnabled && !hasImage) ||
              (isCheckInEnabled && showAddFaces && !hasImage) ||
              isSubmitting
            }
          >
            {isSubmitting ? (
              <Spinner className="submit-spinner" role="status" />
            ) : (
              buttonText
            )}
          </Button>
        </div>
      }
      hidePopover={
        (elementId && hasImage) || (isCheckInEnabled && !showAddFaces)
      }
      placement="top"
      popoverHeader="Cannot continue"
      popoverBody={
        elementId && !hasImage
          ? 'Upload a face with at least 1 image to enable adding a new person.'
          : 'Please start by entering a name'
      }
    />
  );
};

export default AddFaceButton;
