import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const PopoverTrigger = (props) => {
  const { content, placement, hidePopover, popoverHeader, popoverBody } = props;

  const popover = (
    <Popover className="custom-popover">
      <Popover.Header>{popoverHeader}</Popover.Header>
      <Popover.Body>{popoverBody}</Popover.Body>
    </Popover>
  );

  if (hidePopover) return content;
  else
    return (
      <OverlayTrigger trigger="hover" placement={placement} overlay={popover}>
        {content}
      </OverlayTrigger>
    );
};

export default PopoverTrigger;
