import axios from 'axios';

export const getHttp = (baseUrl) => {
  // TODO look to grab from redux state when redux implemented?
  const accessToken = localStorage.getItem('accessToken');

  const instance = axios.create({
    baseURL: baseUrl,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return instance;
};
