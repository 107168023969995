import { getFileImg } from '../Utils';
import { getUploadedFaces } from './api/faces';

export const getFaceUploaded = async (workspaceId) => {
  const facesUploaded = await getUploadedFaces(workspaceId);
  console.log(facesUploaded);

  // complete faces – faces that have enough clips and have no creation
  let uploadedFaces = [];

  facesUploaded.forEach((faceObj) => {
    faceObj.face.img = getFileImg(faceObj.files[0]);

    // if (!generatedFacesIds.includes(faceObj.face.elementId))
    uploadedFaces.push(faceObj.face);
  });

  return { uploadedFaces };
};
