import React from 'react';
import { Button } from 'react-bootstrap';
import {
  BsBoxArrowUpRight,
  BsDownload,
  BsTrash3,
  BsMagic,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

import previewApe from '../../assets/report-preview-ape.png';
import previewHarvard from '../../assets/report-preview-harvard.png';
import previewZoom from '../../assets/report-preview-zoom.png';

import PremiumIcon from '../../components/icons/premiumIcon';
import SideNav from '../../components/sideNav/sideNav';

import './analytics.css';

const Analytics = (props) => {
  const { isSideNav } = props;

  const reports = [
    {
      preview: previewZoom,
      name: 'SSFC Campaign Infographics',
      link: 'https://drive.google.com/file/d/1tU3voC1qEdRP6JlEpa7xkK5Oulkwq84v/view?usp=sharing',
      isEnabled: true,
    },
    {
      preview: previewHarvard,
      name: 'SFFC X Zoom Event 2',
      link: '',
      isEnabled: false,
    },
    {
      preview: previewApe,
      name: 'SFFC X Zoom Event 3',
      link: '',
      isEnabled: false,
    },
  ];

  return (
    <div className="page-container with-side-nav no-footer">
      {isSideNav && <SideNav />}

      <div className="content-header">
        <h1 className="heading">Analytics</h1>
        <div>Recent Campaign Infographics</div>
      </div>

      <div className="page-content">
        <table className="reports-table">
          <thead>
            <tr>
              <th>Preview</th>
              <th>Name</th>
              <th></th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => {
              return (
                <tr>
                  <td className="preview-col">
                    <img src={report.preview} alt="Report Preview" />
                  </td>

                  <td>{report.name}</td>

                  <td>
                    {report.isEnabled ? (
                      <Link
                        to={report.link}
                        target="_blank"
                        className="btn btn-blue text-with-icon"
                      >
                        <BsMagic />
                        <div>Free</div>
                      </Link>
                    ) : (
                      <Link
                        to="/pricing#reports-packs"
                        target="_blank"
                        className="btn btn-primary text-with-icon"
                      >
                        <PremiumIcon />
                        <div>Buy</div>
                      </Link>
                    )}
                  </td>

                  <td className="action-icons-col">
                    <fieldset disabled={!report.isEnabled}>
                      <Button variant="square-icon">
                        <BsBoxArrowUpRight />
                      </Button>
                      <Button variant="square-icon">
                        <BsDownload />
                      </Button>
                      <Button variant="square-icon">
                        <BsTrash3 />
                      </Button>
                    </fieldset>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Analytics;
