const config = {
  workspaceId: process.env.REACT_APP_WORKSPACE_ID || '',
  apiKey: process.env.REACT_APP_CC_API_KEY,
  apiBaseUrl: {
    auth: 'https://auth.crowdclip.co',
    elements: 'https://elements.au.crowdclip.co',
    media: 'https://media-296eb4.au.crowdclip.co',
    sessions: 'https://sessions.au.crowdclip.co',
    upload: 'https://upload-296eb4.au.crowdclip.co',
  },
  zipUrl: {
    fifaTemplateZip: process.env.REACT_APP_FIFA_TEMPLATE_ZIP,
    fifaFacesZip: process.env.REACT_APP_FIFA_FACES_ZIP,
    fifaFootageZip: process.env.REACT_APP_FIFA_FOOTAGE_ZIP,
    weddingTemplateZip: process.env.REACT_APP_WEDDING_TEMPLATE_ZIP,
    weddingFacesZip: process.env.REACT_APP_WEDDING_FACES_ZIP,
    weddingFootageZip: process.env.REACT_APP_WEDDING_FOOTAGE_ZIP,
  },
};

export default config;
