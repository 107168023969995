import React, { useState, useEffect } from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { workspaceId, goTo, isEmpty, isPlural } from '../../Utils';
import ContentFooter from '../../components/contentFooter';
import ContentHeader from '../../components/contentHeader';
import GeneratedVideosAlert from '../../components/generatedVideosAlert';
import ProjectBuilderStepper from '../../components/projectBuilderStepper';
import SideNav from '../../components/sideNav/sideNav';
import UploadInfo from '../../components/uploadInfo';

import { generateCreations } from '../../services/api/creations';
import { getRequiredShots } from '../../services/api/template';
import { getFaceGroups } from '../../services/faceGroupsService';

const ConfirmFaces = (props) => {
  const { isSideNav } = props;

  const [requiredShots, setRequiredShots] = useState([]);
  const [generatedFaces, setGeneratedFaces] = useState([]);
  const [completeFaces, setCompleteFaces] = useState([]);
  const [incompleteFaces, setIncompleteFaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const onMount = async () => {
      if (isLoading) {
        const { success, requiredShots } = await getRequiredShots(workspaceId);

        if (success) {
          const { generatedFaces, completeFaces, incompleteFaces } =
            await getFaceGroups(workspaceId);

          setRequiredShots(requiredShots);
          setGeneratedFaces(generatedFaces);
          setCompleteFaces(completeFaces);
          setIncompleteFaces(incompleteFaces);
        } else {
          toast.error(
            'Template not found. You are redirected to template upload.',
            { toastId: 'template-not-found' },
          );
          navigate('/video-upload');
        }
        setIsLoading(false);
      }
    };

    onMount();
  });

  const onClickBack = () => {
    localStorage.setItem('projectBuilderStage', '/upload-footage');
    goTo('/upload-footage');
  };

  const onClickContinue = async () => {
    const { success } = await generateCreations(workspaceId);
    if (success) {
      localStorage.setItem('generationStatus', 'started');
      localStorage.setItem('projectBuilderStage', '/generate-videos');
      goTo('/generate-videos');
    }
  };

  if (isLoading) {
    return (
      <div className="page-spinner">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  let infoPoints = [
    'The recognised clips are not long enough',
    'The video aspect ratio is incorrect (should be NxN)',
    'The face image or raw footage resolution is too low',
  ];

  let subtitle =
    "No personalised videos could be created. Click 'Back' to upload more footage.";
  if (!isEmpty(completeFaces))
    subtitle = `Based on the provided footage – ${completeFaces.length} personalised videos will be created. Click 'Back' to upload more footage, or 'Continue' to generate videos.`;
  else if (!isEmpty(generatedFaces))
    subtitle = `All videos are generated. Click <a href='/generate-videos'>here</a> to view the generated videos.`;

  return (
    <div className="page-container with-side-nav">
      {isSideNav && <SideNav />}

      <ProjectBuilderStepper activeStep={3} />

      <ContentHeader title="Confirm the faces" subtitle={subtitle} />

      <div className="page-content">
        <GeneratedVideosAlert
          alertText={
            `Personalised video${isPlural(generatedFaces)} of ` +
            `<b>${generatedFaces.map((face) => face.name).join(', ')}</b>` +
            " already exist and won't be re-generated."
          }
        />

        {!isEmpty(completeFaces) && (
          <div className="faces-container">
            {completeFaces.map((face) => {
              return (
                <div className="face-block">
                  <img
                    src={face.img}
                    className="face-icon"
                    alt={`${face.name} Icon`}
                  />
                  <div className="face-name">{face.name}</div>
                </div>
              );
            })}
          </div>
        )}

        {!isEmpty(incompleteFaces) && (
          <React.Fragment>
            <hr />

            <div className="page-block">
              <h4>
                Unfortunately, personalised video{isPlural(incompleteFaces)}{' '}
                cannot be generated for {incompleteFaces.length} face
                {isPlural(incompleteFaces)}
              </h4>

              <div className="note">
                To upload more footage meeting the requirements, click 'Back'.
                Clicking 'Continue' will start generating videos.
              </div>

              <div className="faces-container incomplete">
                {incompleteFaces.map((faceObj) => {
                  let matchedClipsNum =
                    requiredShots.length - faceObj.missingShots.length;
                  let now = (matchedClipsNum / requiredShots.length) * 100;

                  return (
                    <div className="face-block">
                      <img
                        src={faceObj.face.img}
                        className="face-icon"
                        alt={`${faceObj.face.name} Icon`}
                      />

                      <div className="face-details">
                        <h5>{faceObj.face.name}</h5>

                        <ProgressBar
                          className={`face-progress ${
                            now === 0 ? 'empty' : ''
                          }`}
                          now={now}
                          label={`${matchedClipsNum}/${requiredShots.length}${
                            now >= 25 || now === 0 ? ' clips' : ''
                          }`}
                        />

                        <ul className="req-shots-list">
                          {requiredShots.map((shot) => {
                            return faceObj.missingShots.includes(shot) ? (
                              <li className="text-with-icon no">
                                <BsXCircleFill />
                                <div>Clip of {shot} seconds</div>
                              </li>
                            ) : (
                              <li className="text-with-icon yes">
                                <BsCheckCircleFill />
                                <div>Clip of {shot} seconds</div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <UploadInfo
              infoPoints={infoPoints}
              heading="Here are some common reasons why there might be missing clips:"
              note="Try to edit your footage and re-upload. Click 'Back' to return to the footage upload page."
              premiumMsg="to get access to more formats and blah blah blah."
            />
          </React.Fragment>
        )}
      </div>

      <ContentFooter
        onClickBack={() => onClickBack()}
        onClickContinue={() => onClickContinue()}
        allowContinue={!isEmpty(completeFaces)}
        popoverHeader="Cannot continue"
        popoverBody="No videos could be generated"
      />
    </div>
  );
};

export default ConfirmFaces;
