import React from 'react';
import { Button } from 'react-bootstrap';

import { goTo } from '../Utils.js';
import PopoverTrigger from './popoverTrigger';

class ContentFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.clearState = this.clearState.bind(this);
  }

  clearState() {
    localStorage.clear();
    sessionStorage.clear();
    goTo('/');
  }

  logOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');

    sessionStorage.clear();

    goTo('/');
  }

  render() {
    const {
      onClickBack,
      onClickContinue,
      allowContinue,
      popoverHeader,
      popoverBody,
      additionalButton,
    } = this.props;

    return (
      <div className="footer">
        <div className="button-group">
          <div>
            {onClickBack && (
              <Button variant="secondary" onClick={onClickBack}>
                Back
              </Button>
            )}
          </div>
          <div>
            {additionalButton}
            {onClickContinue &&
              (allowContinue ? (
                <Button onClick={onClickContinue}>Continue</Button>
              ) : (
                <PopoverTrigger
                  content={<Button disabled>Continue</Button>}
                  placement="top"
                  popoverHeader={popoverHeader}
                  popoverBody={popoverBody}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ContentFooter;
