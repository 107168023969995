import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import actaiLogo from '../../assets/actai-logo.png';
import aflLogo from '../../assets/afl-logo.png';
import harvardLogo from '../../assets/harvard-logo.png';

import Logo from '../../components/logo';
import ShareFooter from '../../components/shareFooter';

import { getCreationResults } from '../../services/api/creations';
import { getWorkspaceName } from '../../services/api/workspaces';

import withRouter from '../../withRouter';

import '../../styles/share.css';

class ShareHub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generatedFaces: [],
      workspaceName: 'Workspace',
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { workspaceId } = this.props.params;

    const generatedFaces = await getCreationResults(workspaceId, true);
    const workspaceName = await getWorkspaceName(workspaceId);

    this.setState({ generatedFaces, workspaceName, isLoading: false });
  }

  getLogoImage() {
    const { workspaceName } = this.state;

    if (workspaceName.includes('ACTAI')) return actaiLogo;
    else if (workspaceName.includes('AFL')) return aflLogo;
    else if (workspaceName.includes('Harvard')) return harvardLogo;
    return false;
  }

  render() {
    const { generatedFaces, workspaceName, isLoading } = this.state;

    const logo = this.getLogoImage();

    if (isLoading) {
      return (
        <div className="page-spinner">
          <Spinner animation="border" role="status" />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="page-container share-page">
          <Logo withTagLine={true} />

          <h2>
            <b>{workspaceName}</b> has created {generatedFaces.length}{' '}
            personalised videos!
          </h2>

          <hr />

          <div className="page-content">
            {logo && <img src={logo} alt="Logo" className="share-page-logo" />}

            <h4>Please find videos below:</h4>
            <div className="faces-container">
              {generatedFaces.map((face) => {
                return (
                  <Link
                    to={window.location.href + '/' + face.elementId}
                    target="_blank"
                    className="face-block"
                  >
                    <img
                      src={face.img}
                      className="face-icon"
                      alt={`${face.name} Icon`}
                    />
                    <div className="face-name">{face.name}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <ShareFooter />
      </React.Fragment>
    );
  }
}

export default withRouter(ShareHub);
