import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { BsPersonCircle, BsPlusCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Spinner } from 'reactstrap';

import { goTo, getBase64 } from '../../Utils';
import AddFaceButton from '../../components/addFaceButton';
import AddFaceNameForm from '../../components/addFaceNameForm';
import ContentFooter from '../../components/contentFooter';
import ContentHeader from '../../components/contentHeader';
import FacePreviewModal from '../../components/facePreviewModal';
import FacesSummary from '../../components/facesSummary';
import GeneratedVideosAlert from '../../components/generatedVideosAlert';
import ProjectBuilderStepper from '../../components/projectBuilderStepper';
import SideNav from '../../components/sideNav/sideNav';
import UploadInfo from '../../components/uploadInfo';
import ZipModal from '../../components/zipModal';

import config from '../../config';
import { indexFaces, privateFaceCreate } from '../../services/api/faces';
import { getFaceUploaded } from '../../services/faceUploadService';
import { uploadFile } from '../../services/uploadFileService';
import { getUploadFaceText } from './uploadFaceText';

const currentWorkspace = JSON.parse(localStorage.getItem('currentWorkspace'));
const workspaceId = currentWorkspace?.workspaceId || config.workspaceId;

const UploadFaces = (props, args) => {
  const { isSideNav } = props;

  const user = JSON.parse(localStorage.getItem('user'));
  const isCheckInEnabled = user.enableFacesCheckIn;

  // check if localstorage contains face data when refresh page
  const storedFaceName = localStorage.getItem('faceName');
  const storedElementId = localStorage.getItem('elementId');
  const uploadedImagesFromStorage = localStorage.getItem('uploadedImages');
  const storedShowAddFaces = localStorage.getItem('showAddFaces');

  const initialImages = uploadedImagesFromStorage
    ? JSON.parse(uploadedImagesFromStorage)
    : [{ imageUrl: '' }, { imageUrl: '' }, { imageUrl: '' }];

  // handle add new face
  const [face, setFace] = useState({
    name: storedFaceName || '',
    images: initialImages,
  });

  const [elementId, setElementId] = useState(storedElementId || '');

  // handle validate the inputbox
  const [enterButtonDisabled, setEnterButtonDisabled] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [fileInfoArray, setFileInfoArray] = useState([]);
  const [isFacesLoaded, setIsFacesLoaded] = useState(false);
  const [uploadedFacesArray, setUploadedFacesArray] = useState([]);
  const [fetchNamesError, setFetchNamesError] = useState(false);
  const [showAddFaces, setShowAddFaces] = useState(
    !user.enableFacesCheckIn || storedShowAddFaces,
  );
  const [clickedImage, setClickedImage] = useState(null);
  const [faceSubmitted, setFaceSubmitted] = useState(false);

  const [imgModal, setImgModal] = useState(false);
  const toggleImgModal = () => setImgModal(!imgModal);

  // show modal on page load if user is freemium
  const [showZipModal, setShowZipModal] = useState(
    user?.userType === 'freemium',
  );

  const { title, subtitle, facesSummaryText, infoPoints } = getUploadFaceText(
    showAddFaces,
    isFacesLoaded,
    uploadedFacesArray,
    fetchNamesError,
  );

  const fetchUploadedFaces = () => {
    getFaceUploaded(workspaceId)
      .then((res) => {
        setUploadedFacesArray(res.uploadedFaces);
        setIsFacesLoaded(true);
      })
      .catch((err) => {
        console.error('Error fetching uploaded faces:', err);
        setFetchNamesError(true);
      });
  };

  useEffect(() => {
    fetchUploadedFaces();
  }, []);

  const validateInput = (enteredName) => {
    const regName = /^[a-zA-Z]+(\s[a-zA-Z]+)+$/;
    const isValid = enteredName.trim().length > 0 && regName.test(enteredName);
    return isValid;
  };

  const handleOnChangeInput = (e) => {
    const enteredName = e.target.value;
    setFace({ ...face, name: enteredName });

    const isValid = validateInput(enteredName);
    setEnterButtonDisabled(!isValid);
    setIsInvalid(!isValid);
  };

  const handleSubmitName = (e) => {
    e.preventDefault();

    setIsLoading(true);
    // can generate elementId for the face
    privateFaceCreate(face.name, workspaceId)
      .then((elementId) => {
        setElementId(elementId);
        localStorage.setItem('faceName', face.name);
        localStorage.setItem('elementId', elementId);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setApiError(true);
      });
  };

  // handle drag and drop upload props
  const { Dragger } = Upload;
  const draggerProps = {
    multiple: false,
    maxCount: 1,
    accept: 'image/*',
  };

  // handle preview functionality
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const onChangePreview = (info, index) => {
    getBase64(info.file.originFileObj, (url) => {
      const updatedImages = [...face.images];
      updatedImages[index].imageUrl = url;
      setFace((prevFace) => ({ ...prevFace, images: updatedImages }));
      // Store the updated face data in local storage
      localStorage.setItem('uploadedImages', JSON.stringify(updatedImages));
    });
    // Push the info to the array
    setFileInfoArray((prevFileInfoArray) => {
      const newFileInfoArray = [...prevFileInfoArray];
      newFileInfoArray[index] = info;
      return newFileInfoArray;
    });
  };

  // const handleDeleteFace = (index) => {
  //   const updatedImages = [...face.images];
  //   updatedImages[index].imageUrl = '';
  //   setFace((prevFace) => ({ ...prevFace, images: updatedImages }));
  //   // Update local storage when an image is removed
  //   localStorage.setItem('uploadedImages', JSON.stringify(updatedImages));
  // };

  const handleAddFace = () => {
    setShowAddFaces(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmitFaces = async () => {
    setIsSubmitting(true);

    try {
      const uploadPromises = fileInfoArray.map((item) =>
        uploadFile({
          file: item.file.originFileObj,
          workspaceId,
          parentElementId: elementId,
        }),
      );

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);

      // All files have been successfully uploaded
      await indexFaces(elementId);

      toast.success(`${face.name} file uploaded successfully.`);
      // Clean up and reload
      setTimeout(() => {
        setFaceSubmitted(true);
        clearFacesDetails();
        window.location.reload(true);
      }, 1200);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Try again later');
      setApiError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearFacesDetails = () => {
    localStorage.removeItem('faceName');
    localStorage.removeItem('elementId');
    localStorage.removeItem('uploadedImages');
  };

  const handleClickContinue = () => {
    goTo('/upload-footage');
    clearFacesDetails();
    localStorage.removeItem('showAddFaces');
  };

  const handleClickBack = () => {
    goTo('./video-upload');
    clearFacesDetails();
    localStorage.removeItem('showAddFaces');
  };

  const handleClickImg = (clickedFace) => {
    toggleImgModal();
    setClickedImage(clickedFace);
  };

  // Determine if there is at least one image URL
  const hasImage = face.images.some((image) => !!image.imageUrl);

  if (!isFacesLoaded) {
    return (
      <div className="page-spinner">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <div className="page-container with-side-nav">
      {isSideNav && <SideNav />}

      <ProjectBuilderStepper activeStep={1} />

      <ContentHeader
        title={title}
        subtitle={subtitle}
        onZipClick={() => setShowZipModal(true)}
      />
      <div className="page-content">
        <GeneratedVideosAlert alertText="If you add new faces, they will only be recognised in footage added after. All previously uploaded files won't be re-processed." />

        {!elementId && showAddFaces && (
          <Alert color="danger">
            Please submit a full name to start adding a person
          </Alert>
        )}

        {showAddFaces && (
          <AddFaceNameForm
            face={face}
            handleOnChangeInput={handleOnChangeInput}
            handleSubmitName={handleSubmitName}
            isInvalid={isInvalid}
            enterButtonDisabled={enterButtonDisabled}
          />
        )}

        {elementId && (
          <React.Fragment>
            <UploadInfo
              isCollapse={true}
              type="faces"
              infoPoints={infoPoints}
              heading="When uploading the faces, keep the following in mind:"
              note="Otherwise, our recognition might not identify the person."
              premiumMsg="to get access to more formats and blah blah blah."
              addClass="fade-in"
            />
            <div>
              Please upload images below for <b>{face.name}</b>
            </div>

            <div className="faces-uploads-container fade-in">
              {face.images.map((image, index) => (
                <Dragger
                  {...draggerProps}
                  className="face-upload pulsing"
                  key={index}
                  onChange={(info) => onChangePreview(info, index)}
                  onPreview={handlePreview}
                  showUploadList={false}
                >
                  {image.imageUrl === '' ? (
                    <>
                      <BsPersonCircle size={100} />
                      <div>Click or drag file to this area to upload</div>
                    </>
                  ) : (
                    <img
                      className="profile-image"
                      src={image.imageUrl}
                      alt="Uploaded Face"
                    />
                  )}
                </Dragger>
              ))}
            </div>
            <AddFaceButton
              handleClickBtn={handleSubmitFaces}
              isCheckInEnabled={isCheckInEnabled}
              hasImage={hasImage}
              showAddFaces={showAddFaces}
              isSubmitting={isSubmitting}
              elementId={elementId}
              buttonText={'Submit'}
              className={'face-submit-btn'}
            />
          </React.Fragment>
        )}

        <div className="fw-bold">{facesSummaryText}</div>
        {uploadedFacesArray?.length > 0 && (
          <div className="faces-container">
            {uploadedFacesArray.map((face) => {
              return (
                <FacesSummary face={face} handleClickImg={handleClickImg} />
              );
            })}
            <div className="face-block">
              {!showAddFaces && (
                <>
                  <AddFaceButton
                    handleClickBtn={handleAddFace}
                    isCheckInEnabled={isCheckInEnabled}
                    hasImage={hasImage}
                    showAddFaces={showAddFaces}
                    isSubmitting={isSubmitting}
                    elementId={elementId}
                    buttonText={'+'}
                    className={'new-face-btn'}
                  />
                  <div className="fw-bold">Add a new face</div>
                </>
              )}
            </div>
          </div>
        )}

        {!isCheckInEnabled && (
          <div className="selling-point text-with-icon big-icon note bold">
            <BsPlusCircleFill />
            <div>
              Purchase our <Link to="/plans">check-in page</Link> to streamify
              faces uploading process.
            </div>
          </div>
        )}

        {apiError && (
          <Alert color="warning">
            Unfortunately, there was an error when processing this page, please
            try again later.
          </Alert>
        )}

        {isLoading && (
          <div className="spinner-container">
            <Spinner animation="border" role="status" />
          </div>
        )}
      </div>

      <ContentFooter
        onClickBack={handleClickBack}
        onClickContinue={handleClickContinue}
        allowContinue={
          faceSubmitted || (!elementId && uploadedFacesArray?.length > 0)
        }
        popoverHeader="Cannot continue"
        popoverBody={
          elementId && !hasImage
            ? 'Please upload at least 1 image to continue'
            : "Please upload at least 1 person's face to continue"
        }
        additionalButton={
          uploadedFacesArray?.length === 0 &&
          isCheckInEnabled &&
          !showAddFaces && (
            <AddFaceButton
              handleClickBtn={handleAddFace}
              isCheckInEnabled={isCheckInEnabled}
              hasImage={hasImage}
              showAddFaces={showAddFaces}
              isSubmitting={isSubmitting}
              elementId={elementId}
              buttonText={'Add a face'}
            />
          )
        }
      />

      <ZipModal
        show={showZipModal}
        onHide={() => setShowZipModal(false)}
        type="Faces"
      />

      <FacePreviewModal
        imgModal={imgModal}
        toggleImgModal={toggleImgModal}
        clickedImage={clickedImage}
        {...args}
      />
    </div>
  );
};

export default UploadFaces;
