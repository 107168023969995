import React from 'react';

import { BsLightningCharge } from 'react-icons/bs';

const PremiumIcon = (props) => {
  const { withCircle } = props;

  return (
    <div className={`custom-icon premium-icon ${withCircle ? 'circle' : ''}`}>
      <BsLightningCharge />
    </div>
  );
};

export default PremiumIcon;
