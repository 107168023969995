// workspace types
export const WORKSPACE_TYPE_DEFAULT = 1;
export const WORKSPACE_TYPE_OTHER = 0;

// password length
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;

// email sending
export const RESEND_EMAIL_IN_SEC = 60;
export const LINK_EXPIRE_IN_MIN = 60;

// upload footage limit
export const FOOTAGE_UPLOAD_LIMIT = 3;
export const MAX_FILE_SIZE_MB = 300;
