import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import {
  BsCheckCircleFill,
  BsExclamationCircleFill,
  BsXCircleFill,
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsPlusCircleFill,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { workspaceId, goTo, isEmpty, isPlural } from '../../Utils';
import landscapeImg from '../../assets/landscape.png';
import ContentFooter from '../../components/contentFooter';
import ContentHeader from '../../components/contentHeader';
import FileDropArea from '../../components/fileDropArea';
import GeneratedVideosAlert from '../../components/generatedVideosAlert';
import ProjectBuilderStepper from '../../components/projectBuilderStepper';
import SideNav from '../../components/sideNav/sideNav';
import UploadInfo from '../../components/uploadInfo';
import ZipModal from '../../components/zipModal';

import { getRequiredShots } from '../../services/api/template';
import { getFootageGroups } from '../../services/footageGroupsService';

const statusDict = {
  IN_QUEUE: 'In queue...',
  UPLOADING: 'Uploading the file...',
  GENERATING_THUMBNAIL: 'Generating the thumbnail...',
  EXTRACTING_CLIPS: 'Extracting the clips...',
  PROCESSED: 'The file is processed!',
  ERROR: 'An error occurred!',
};

const UploadFootage = (props) => {
  const { isSideNav } = props;

  const user = JSON.parse(localStorage.getItem('user'));

  // uncomment next 2 lines to enable UGC
  // user.enableUGC = true;
  // localStorage.setItem('user', JSON.stringify(user));

  const [requiredShots, setRequiredShots] = useState([]);
  const [finishedFootage, setFinishedFootage] = useState([]);
  const [processingFootage, setProcessingFootage] = useState([]);
  const [errorFootage, setErrorFootage] = useState([]);
  const [clipGroups, setClipGroups] = useState([]);
  const [isNoFootage, setIsNoFootage] = useState(false);
  const [isShowUpload, setIsShowUpload] = useState(!user.enableUGC);
  const [isShowProcessed, setIsShowProcessed] = useState(user.enableUGC);
  const [isLoading, setIsLoading] = useState(true);

  // show modal on page load if user is freemium
  const [showZipModal, setShowZipModal] = useState(
    user?.userType === 'freemium',
  );

  useEffect(() => {
    const onMount = async () => {
      if (isLoading) {
        const { requiredShots } = await getRequiredShots(workspaceId);
        setRequiredShots(requiredShots);

        await checkFootageData(true);
      }
    };

    onMount();
  });

  const setFootageResults = (footageResults) => {
    setFinishedFootage(footageResults.finishedFootage);
    setProcessingFootage(footageResults.processingFootage);
    setErrorFootage(footageResults.errorFootage);
    setClipGroups(footageResults.clipGroups);
    setIsNoFootage(false);
  };

  const checkFootageData = async (didMount) => {
    let footageResults = await getFootageGroups(workspaceId);

    setIsLoading(false);

    switch (footageResults.stage) {
      case 'no-footage':
        // if no footage uploaded – big file drop area will appear
        setIsNoFootage(true);
        break;

      case 'processing':
        // if processing – update state and re-fetch every 2.5 seconds
        setFootageResults(footageResults);
        setTimeout(async () => await checkFootageData(), 2500);
        break;

      case 'finished':
        // if all footage is processed – update state and show toast (if page didn't just mount)
        setFootageResults(footageResults);
        if (!didMount)
          toast.success(
            "All footage is processed! Upload more files or click 'Continue'",
            { toastId: 'footage-processed-success' },
          );
        break;

      default:
        break;
    }
  };

  // only removing from front-end, footage will still appear when refreshed
  const removeFootage = (elementId) => {
    let video = errorFootage?.filter(
      (footage) => footage.elementId === elementId,
    )[0];
    const index = errorFootage.indexOf(video);
    errorFootage.splice(index, 1);

    setErrorFootage(errorFootage);
  };

  // separates clip groups into found and unfound faces
  const getFaceGroups = () => {
    let foundFaces = [];
    let unfoundFaces = [];

    clipGroups.forEach((group) => {
      if (!isEmpty(group.clips)) foundFaces.push(group);
      else unfoundFaces.push(group.face.name);
    });

    // sorts the found faces based on number of clips in descending order
    let sortedClipGroups = foundFaces.sort((one, other) => {
      return other.clips.length - one.clips.length;
    });

    return { foundFaces: sortedClipGroups, unfoundFaces };
  };

  // renders clip groups for found faces
  const renderGroupedClips = (clipGroups) => {
    return (
      <div className="clips-groups-container">
        {clipGroups.map((group) => {
          return (
            <div className="clips-group">
              <div className="text-with-icon big-icon">
                <img
                  className="face-icon"
                  src={group.img}
                  alt={`${group.face.name} Icon`}
                />

                <h5>
                  {group.face.name} ({group.clips.length} clip
                  {isPlural(group.clips)})
                </h5>
              </div>

              {renderThumbnailsContainer(group.clips)}
            </div>
          );
        })}
      </div>
    );
  };

  // renders the thumbanails container for provided videos array
  const renderThumbnailsContainer = (videos) => {
    return (
      <div className="thumbanails-container">
        {videos.map((video) => {
          return (
            <div className="thumbanail-block">
              {video.status && (
                // will only present on processing videos
                <div className={`img-overlay ${video.status}`}>
                  <div className="loading-icon">
                    <BsCheckCircleFill className="check-icon" size="2rem" />
                    <Spinner animation="border" role="status" />
                    <BsExclamationCircleFill
                      className="error-icon"
                      size="2rem"
                    />
                  </div>
                  {/* TODO: either remove x btn or add ability to actually remove footage */}
                  <BsXCircleFill
                    onClick={() => removeFootage(video.elementId)}
                    className="delete-icon"
                    size="1.5rem"
                  />
                  <div className="loading-description">
                    {statusDict[video.status]}
                  </div>
                </div>
              )}
              {video.segment && (
                // will only present on clips
                <div className="clip-duration">
                  <div>{video.segment.durationMilliseconds / 1000}s</div>
                </div>
              )}

              <img
                src={
                  video.frameId
                    ? `https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.thumbnails/${video.frameId}/720x405.png`
                    : landscapeImg
                }
                className="thumbnail"
                alt="Video Thumbnail"
              />
              <div className="file-name">{video.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const onClickContinue = () => {
    localStorage.setItem('projectBuilderStage', '/confirm-faces');
    goTo('/confirm-faces');
  };

  if (isLoading) {
    return (
      <div className="page-spinner">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  const { foundFaces, unfoundFaces } = getFaceGroups();

  const rawFootage = isShowProcessed
    ? processingFootage.concat(finishedFootage)
    : processingFootage;

  const isProcessing = !isEmpty(processingFootage);

  const isEnoughClips = foundFaces[0]?.clips.length >= requiredShots.length;

  const infoPoints = [
    'The files need to match the template settings, such as FPS, aspect ratio, orientation (i.e. landscape or portrait), and resolution (the same or higher)',
    'Video codec has to be H.264 (i.e. MPEG-4 or MOV formats)',
    'Audio codec has to be AAC',
    'Use footage with good lighting',
    'Have face and eyes clearly showing, avoid hats, glasses, or masks',
  ];

  let subtitle =
    'Now upload video file(s) containing the shots you would like to use. Ideally, this would contain close ups of the individuals you wish to create personalised videos of. Please upload no more than 3 files at a time. And each file should be less than 300 MB.';

  if (user.enableUGC) {
    subtitle =
      'You purchased a premium pack for raw footage. You have enabled your own branded web app for UGC raw uploads.';

    if (isNoFootage) subtitle += ' No UGC has been uploaded yet.';
    else if (!isEnoughClips)
      subtitle +=
        ' Unfortinately, there is not enough footage uploaded to create a personalised video.';
    else subtitle += " You can simply click 'Continue' button to confirm.";
  }

  let allowContinue = true;
  let popoverHeader = '';
  let popoverBody = '';

  if (isEmpty(requiredShots)) {
    allowContinue = false;
    popoverHeader = 'No template';
    popoverBody = 'Upload the template first.';
  } else if (!isEnoughClips) {
    allowContinue = false;
    popoverHeader = 'Not enough clips';
    popoverBody = `Minimum of ${requiredShots.length} clips are required to create a personalised video.`;
  }

  return (
    <div className="page-container with-side-nav">
      {isSideNav && <SideNav />}

      <ProjectBuilderStepper activeStep={2} />

      <ContentHeader
        title="Upload your raw footage"
        subtitle={subtitle}
        onZipClick={() => setShowZipModal(true)}
      />

      <div className="page-content footage-upload">
        <GeneratedVideosAlert alertText="If you upload new footage, it will only be used in videos for new faces. All generated videos will remain the same." />

        {isShowUpload && (
          <UploadInfo
            isCollapse={true}
            type="footage"
            infoPoints={infoPoints}
            heading="When uploading the footage, keep the following in mind:"
            note="Otherwise, our recognition might not identify the person."
            premiumMsg="to get access to more formats and blah blah blah."
          />
        )}

        {isNoFootage ? (
          <FileDropArea type="big" onDrop={() => checkFootageData()} />
        ) : (
          <React.Fragment>
            {isShowUpload && (
              <FileDropArea type="shrunk" onDrop={() => checkFootageData()} />
            )}

            <div className="page-block">
              <div className="sub-heading-container">
                <h4>In progress uploads</h4>
                <div className="text-with-icon">
                  <div>Processed:</div>
                  <div
                    className="icon"
                    onClick={() => setIsShowProcessed(!isShowProcessed)}
                  >
                    {isShowProcessed ? (
                      <BsFillEyeFill />
                    ) : (
                      <BsFillEyeSlashFill />
                    )}
                  </div>
                </div>
              </div>
              {!isProcessing && (
                <div className="text-with-icon big-icon">
                  <BsCheckCircleFill className="success-icon" />
                  All footage is processed. You can upload more files above.
                </div>
              )}
              {!isEmpty(rawFootage) && renderThumbnailsContainer(rawFootage)}
            </div>

            {!isEmpty(errorFootage) && (
              <div className="page-block">
                <h4>Couldn't process</h4>
                {renderThumbnailsContainer(errorFootage)}
              </div>
            )}

            {!isEmpty(foundFaces) && (
              <React.Fragment>
                <hr />
                <div className="page-block">
                  <h4>
                    Recognised clips ({foundFaces.length} face
                    {isPlural(foundFaces)})
                  </h4>
                  <div>
                    {!isProcessing && !isEmpty(unfoundFaces) && (
                      // Shows note if some faces weren't found
                      <div className="note">
                        Unfortunately, no clips were found for{' '}
                        {unfoundFaces.length} face
                        {isPlural(unfoundFaces)}: {unfoundFaces.join(', ')}.
                      </div>
                    )}

                    {isEmpty(requiredShots) ? (
                      <div className="note">
                        No template was found. Upload the template{' '}
                        <Link onClick={() => goTo('/video-upload')}>here</Link>{' '}
                        to be able to proceed.
                      </div>
                    ) : (
                      <div className="note">
                        Note that {requiredShots.length} clip
                        {isPlural(requiredShots)} are required to create a
                        personalised video.
                      </div>
                    )}
                  </div>
                  {renderGroupedClips(foundFaces)}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {!user.enableUGC && (
          <div className="selling-point text-with-icon big-icon note bold">
            <BsPlusCircleFill />
            <div>
              Purchase our <Link to="/plans">UGC add-on</Link> to streamify
              footage uploading process.
            </div>
          </div>
        )}
      </div>

      {isNoFootage ? (
        <ContentFooter onClickBack={() => goTo('/upload-faces')} />
      ) : (
        <ContentFooter
          onClickContinue={() => onClickContinue()}
          allowContinue={allowContinue}
          popoverHeader={popoverHeader}
          popoverBody={popoverBody}
          additionalButton={
            user.enableUGC && (
              <Button onClick={() => setIsShowUpload(true)}>
                Add Raw Footage
              </Button>
            )
          }
        />
      )}

      <ZipModal
        show={showZipModal}
        onHide={() => setShowZipModal(false)}
        type="Raw Footage"
      />
    </div>
  );
};

export default UploadFootage;
