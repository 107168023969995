import { getFileImg } from '../../Utils';
import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl, media: mediaUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);
const mediaAccessTokenClient = getAccessTokenClient(mediaUrl);
const apiKeyClient = getApiKeyClient(baseUrl);

export const generateCreations = async (workspaceId) => {
  try {
    await mediaAccessTokenClient.get(
      `/api/generate-creations?workspaceId=${workspaceId}`,
    );
    return { success: true };
  } catch (err) {
    throw new Error(`Error generating creations: ${err.message}`);
  }
};

export const getCreationResults = async (workspaceId, isPublic) => {
  try {
    const response = await (isPublic ? apiKeyClient : accessTokenClient).get(
      `api/creation-results?workspaceId=${workspaceId}`,
    );

    let generatedFaces = [];

    response.data.results.forEach((faceObj) => {
      faceObj.face.img = getFileImg(faceObj.images[0]);
      if (faceObj.creations.length > 0) generatedFaces.push(faceObj.face);
    });

    return generatedFaces;
  } catch (err) {
    throw new Error(`Error getting creation results: ${err.message}`);
  }
};

// public API used in share video page
export const getOneCreation = async (parentElementId) => {
  try {
    const response = await apiKeyClient.get(
      `api/files/by-parent-element/${parentElementId}?fileKind=video-creation`,
    );
    return response.data.files[0];
  } catch (err) {
    throw new Error(`Error getting a creation: ${err.message}`);
  }
};
