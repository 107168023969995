import React, { useState } from 'react';
import { Upload } from 'antd';
import { Spinner, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import {
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsGlobe,
  BsPersonCircle,
  BsPersonVideo2,
  BsCheckCircle,
} from 'react-icons/bs';

import { getBase64 } from '../../Utils';

import aflBanner from '../../assets/afl-banner.png';
import aflLogo from '../../assets/afl-logo-square.png';
import landscapeImg from '../../assets/landscape.png';
import UploadInfo from '../../components/uploadInfo';

import { checkIn } from '../../services/checkInService';

import withRouter from '../../withRouter';

import './events.css';

const EventCheckIn = (props) => {
  const { workspaceId } = props.params;

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [selfieFile, setSelfieFile] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState('');
  const [videoFiles, setVideoFiles] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onChangeInput = (e) => {
    setName(e.target.value);
  };

  const onChangeSelfie = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setSelfieUrl(url);
    });
    setSelfieFile(info.file);
  };

  const submitCheckIn = async () => {
    setIsLoading(true);

    await checkIn(workspaceId, name, selfieFile, videoFiles);

    setIsLoading(false);
    setShowConfirmModal(true);
  };

  const clearState = () => {
    setName('');
    setSelfieFile(null);
    setSelfieUrl('');
    setVideoFiles([]);
    setShowConfirmModal(false);
  };

  const ConfirmModal = () => {
    return (
      <Modal
        className="custom-modal confirm-modal"
        show={showConfirmModal}
        onHide={() => clearState()}
        centered
      >
        <Modal.Body className="text-center">
          <BsCheckCircle className="success-icon" size={80} />
          <div>
            Thank you, you have successfully checked in as <b>{name}</b>!
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  let infoPoints = [
    'Have your face and eyes clearly showing',
    'Have a good lighting',
    'Avoid hats, glasses, or masks',
  ];

  return (
    <div className="page-container event">
      <div className="event-header">
        <img className="banner" src={aflBanner} alt="Event Banner" />
        <div className="event-details-wrapper">
          <img className="logo" src={aflLogo} alt="Event Logo" />
          <div className="event-details">
            <div className="event-heading">
              <h4>AFL x Telstra</h4>
              <div>Welcome to your Check-in Page!</div>
            </div>
            <div className="socials">
              <a
                href="https://www.facebook.com/AFL/"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook />
              </a>
              <a
                href="https://www.instagram.com/afl/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram />
              </a>
              <a
                href="https://twitter.com/afl/"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter />
              </a>
              <a
                href="https://www.afl.com.au/"
                target="_blank"
                rel="noreferrer"
              >
                <BsGlobe />
              </a>
              {/* Share button that is not functioning yet */}
              {/* <a href="/" target="_blank" rel="noreferrer">
                <BsReply />
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="page-content">
        <h4>
          Enter your details to receive your own professionally curated,
          personalised video.
        </h4>

        <Form className="check-in-form" noValidate>
          <div className="page-block">
            <h5>Step 1: Fill in your details</h5>

            <FloatingLabel label="Full name">
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={onChangeInput}
                placeholder="Please enter a full name"
                required
              />
            </FloatingLabel>
          </div>

          <UploadInfo
            infoPoints={infoPoints}
            heading="When uploading your photo and videos, keep the following in mind:"
            note="Otherwise, our recognition might not identify you."
          />

          <div className="page-block">
            <h5>Step 2: Upload a selfie</h5>

            <Upload.Dragger
              multiple={false}
              maxCount={1}
              accept="image/*"
              className="shrunk pulsing"
              onChange={(info) => onChangeSelfie(info)}
              showUploadList={false}
            >
              {selfieUrl === '' ? (
                <BsPersonCircle size={80} />
              ) : (
                <img
                  className="profile-image"
                  src={selfieUrl}
                  alt="Uploaded Selfie"
                />
              )}
              <div className="upload-area-text">
                <div>Photo upload (1 max)</div>
                <div>Drag and drop or click to upload</div>
                {selfieUrl !== '' && (
                  <div className="note">
                    Note: This will replace the current image
                  </div>
                )}
              </div>
            </Upload.Dragger>
          </div>

          <div className="page-block">
            <h5>Step 3: Upload your UGC</h5>

            <div className="note">
              You can upload as many videos as you want.
            </div>

            <Upload.Dragger
              multiple={true}
              accept="video/*"
              className="shrunk pulsing"
              onChange={(info) => setVideoFiles(info.fileList)}
              showUploadList={false}
            >
              <BsPersonVideo2 size={80} />
              <div className="upload-area-text">
                <div>Videos upload (1 and more)</div>
                <div>Drag and drop or click to upload</div>
              </div>
            </Upload.Dragger>

            {videoFiles.length > 0 && (
              <div className="footage-upload">
                <h6>Your videos ({videoFiles.length})</h6>
                <div className="thumbanails-container">
                  {videoFiles.map((video) => {
                    return (
                      <div className="thumbanail-block">
                        <img
                          src={landscapeImg}
                          className="thumbnail"
                          alt="Video Thumbnail"
                        />
                        <div className="file-name">{video.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <Button
            onClick={submitCheckIn}
            disabled={
              isLoading || name === '' || !selfieFile || videoFiles.length === 0
            }
          >
            <div className={`${!isLoading}`}>Check In</div>
            <Spinner
              className={`${isLoading}`}
              animation="border"
              role="status"
            />
          </Button>
        </Form>
      </div>

      <ConfirmModal />
    </div>
  );
};

export default withRouter(EventCheckIn);
