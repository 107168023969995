import React from 'react';
import { BsFillRecordFill } from 'react-icons/bs';

const EnabledIcon = () => {
  return (
    <div className="custom-icon enabled-icon circle">
      <BsFillRecordFill />
    </div>
  );
};

export default EnabledIcon;
