import React from 'react';
import { Stepper } from 'react-form-stepper';

import { goTo } from '../Utils.js';

const ProjectBuilderStepper = (props) => {
  const { activeStep } = props;

  const generationStatus = localStorage.getItem('generationStatus');

  const onClickStep = (link) => {
    if (generationStatus) goTo(link);
  };

  const steps = [
    {
      label: 'Upload Template',
      onClick: () => onClickStep('/video-upload'),
    },
    {
      label: 'Upload Faces',
      onClick: () => onClickStep('/upload-faces'),
    },
    {
      label: 'Upload Raw Footage',
      onClick: () => onClickStep('/upload-footage'),
    },
    {
      label: 'Confirm Faces',
      onClick: () => onClickStep('/confirm-faces'),
    },
    {
      label: 'Generate Videos',
      onClick: () => onClickStep('/generate-videos'),
    },
  ];

  return (
    <div className="stepper">
      <Stepper
        steps={steps}
        activeStep={activeStep}
        stepClassName={generationStatus ? '' : 'non-clickable'}
        styleConfig={{
          labelFontSize: '1em',
          activeBgColor: 'rgb(240, 90, 91)',
          completedBgColor: 'rgba(240, 90, 91, 0.5)',
        }}
      />
    </div>
  );
};

export default ProjectBuilderStepper;
