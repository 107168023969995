import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import config from '../../config';
import { getApiKeyClient } from '../client/http';

const INCORRECT_CRED_MESSAGES = [
  'User does not exist',
  'Password hashes do not match',
];

const {
  apiBaseUrl: { sessions: baseUrl },
} = config;
const apiClient = getApiKeyClient(baseUrl);

export const signIn = async (email, password, isKeepSignIn) => {
  const newSession = {
    email,
    password,
    remember: isKeepSignIn,
    origin: 'sessions.au.crowdclip.co',
    service: 'sessions.au',
  };

  try {
    const response = await apiClient.post(`/api/sign-in`, newSession);
    const accessToken = response.data.result.accessToken;
    const user = {
      email,
      session: jwtDecode(accessToken),
      // comment/uncomment to set user as preemium/freemium
      // userType: 'freemium',
      // uncomment to enable user faceCheckIn
      enableFacesCheckIn: true,
      // comment/uncomment to disable/enable UGC
      enableUGC: true,
    };

    // TODO look to using state management like redux to store these
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('user', JSON.stringify(user));

    Cookies.set('token', response.data.result.refreshToken);

    return user;
  } catch (error) {
    const message = error.response.data.message;
    if (INCORRECT_CRED_MESSAGES.includes(message)) {
      throw new Error(
        'Incorrect credentials provided. Ensure both email and password are correct',
      );
    }

    throw error;
  }
};
