import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);
const apiKeyClient = getApiKeyClient(baseUrl);

export const createWorkspace = async (name, userId, type) => {
  const newWorkspace = { name, userId, type };

  try {
    const response = await accessTokenClient.put('api/workspace', newWorkspace);
    return response.data.workspace;
  } catch (err) {
    throw new Error('Error creating workspace', err);
  }
};

export const getUserWorkspaces = async (userId) => {
  const accessTokenClient = getAccessTokenClient(baseUrl);

  try {
    const response = await accessTokenClient.get(
      `api/workspaces?userId=${userId}`,
    );

    const workspaces = response.data.workspaces;
    localStorage.setItem('userWorkspaces', JSON.stringify(workspaces));
    return workspaces;
  } catch (err) {
    throw new Error('Error getting user workspaces', err);
  }
};

// getWorkspaceName is used in public Share Hub and Share Video pages
export const getWorkspaceName = async (workspaceId) => {
  try {
    const response = await apiKeyClient.get(`api/workspaces/${workspaceId}`);
    return response.data.workspace.name;
  } catch (err) {
    throw new Error('Error getting workspace', err);
  }
};
