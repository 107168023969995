import React from 'react';
import { Link } from 'react-router-dom';

import ccLogoGradient from '../assets/cc-logo-gradient.png';
import ccLogoWhite from '../assets/cc-logo-white.png';

const Logo = (props) => {
  const { isWhite, withTagLine } = props;

  return (
    <Link to="/" className="logo">
      <img
        src={isWhite ? ccLogoWhite : ccLogoGradient}
        alt="CrowdClip Logo"
        width="128px"
      />
      {withTagLine && (
        <div className="tag-line">Bring the joy back to video</div>
      )}
    </Link>
  );
};

export default Logo;
