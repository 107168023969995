export const getUploadFaceText = (
  showAddFaces,
  isFacesLoaded,
  uploadedFacesArray,
  fetchNamesError,
) => {
  let title = 'Please upload faces for individuals';
  let subtitle =
    'Add multiple people below and please upload 3 pictures for each person to help us recognize their faces.';
  let facesSummaryText = `Here is the summary of uploaded faces, you have uploaded ${uploadedFacesArray?.length} faces.`;

  if (!showAddFaces) {
    title = 'Please confirm faces';
    subtitle = `You purchased a premium pack for faces ❤️ ! You have enabled your own branded web app for event faces upload. Click "Add a new face" button to add a face, or simply click 'Continue' to confirm.`;
  }

  if (isFacesLoaded && uploadedFacesArray?.length === 0) {
    facesSummaryText = `You haven't uploaded any faces yet. Please start uploading a face.`;
  }

  if (fetchNamesError) {
    facesSummaryText = `Oops, some errors happened with getting the uploaded faces, please try again later.`;
  }

  const infoPoints = [
    'File format should be PNG or JPEG',
    'The photos will increase the chances of successful results',
    'Use hi-res images with good lighting',
    'Have face and eyes clearly showing, avoid hats, glasses, or masks',
  ];

  return {
    title,
    subtitle,
    facesSummaryText,
    infoPoints,
  };
};
