import { getFileImg } from '../Utils';
import { getCreationResults } from './api/creations';
import { getFaceResults } from './api/faces';

export const getFaceGroups = async (workspaceId) => {
  const faceResults = await getFaceResults(workspaceId);

  // generated faces – faces that have a creation
  const generatedFaces = await getCreationResults(workspaceId);

  let generatedFacesIds = generatedFaces.map((face) => face.elementId);

  // complete faces – faces that have enough clips and have no creation
  let completeFaces = [];

  faceResults.complete.forEach((faceObj) => {
    faceObj.face.img = getFileImg(faceObj.files[0]);

    if (!generatedFacesIds.includes(faceObj.face.elementId))
      completeFaces.push(faceObj.face);
  });

  // incomplete faces – faces that don't have enough clips
  let incompleteFaces = [];

  faceResults.incomplete.forEach((faceObj) => {
    faceObj.face.img = getFileImg(faceObj.files[0]);

    let missingShots = [];

    faceObj.shots.forEach((shot) => {
      missingShots.push(shot.segment.durationMilliseconds / 1000);
    });

    incompleteFaces.push({ face: faceObj.face, missingShots });
  });

  return { generatedFaces, completeFaces, incompleteFaces };
};
