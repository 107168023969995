import { isEmpty, getFileImg } from '../Utils';
import { getFootageFiles, getClipResults } from './api/footage';

export const getFootageGroups = async (workspaceId) => {
  let allFiles = await getFootageFiles(workspaceId);

  if (isEmpty(allFiles)) return { stage: 'no-footage' };

  let { files, results } = await getClipResults(workspaceId);

  // array of ids
  let footageIds = [];

  // processing footage
  let processingFootage = [];

  files.uploading.forEach((file) => {
    if (!file.progress) file.status = 'IN_QUEUE';
    else file.status = 'UPLOADING';

    processingFootage.push(file);
    footageIds.push(file.elementId);
  });

  files.processing.forEach((file) => {
    if (!file.frameId) file.status = 'GENERATING_THUMBNAIL';
    else file.status = 'EXTRACTING_CLIPS';

    processingFootage.push(file);
    footageIds.push(file.elementId);
  });

  // sorted by date created so files do not change order when data is updated
  let sortedProcessingFootage = processingFootage.sort((one, other) => {
    // return one.created - other.created;
    if (other.created > one.created) return -1;
    return 1;
  });

  // error footage
  let errorFootage = [];

  files.error.forEach((file) => {
    file.status = 'ERROR';

    errorFootage.push(file);
    footageIds.push(file.elementId);
  });

  // finished footage
  let finishedFootage = [];

  allFiles.forEach((file) => {
    if (!footageIds.includes(file.elementId)) {
      file.status = 'PROCESSED';

      finishedFootage.push(file);
    }
  });

  // clips groups
  let clipGroups = [...results];

  clipGroups.forEach((result) => {
    result.img = getFileImg(result.files[0]);
  });

  return {
    stage: processingFootage.length > 0 ? 'processing' : 'finished',
    finishedFootage,
    processingFootage: sortedProcessingFootage,
    errorFootage,
    clipGroups,
  };
};
