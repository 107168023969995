import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { ProtectedRoute } from './ProtectedRoute.js';

import Header from './components/header';

import Analytics from './pages/Analytics/analytics';

import Brand from './pages/Brand/brand';

import AmbassadorEventCheckIn from './pages/Event/ambassadorEventCheckIn.jsx';
import EventCheckIn from './pages/Event/eventCheckIn';

import ConfirmFaces from './pages/Footage/confirmFaces';
import GenerateVideos from './pages/Footage/generateVideos';
import UploadFootage from './pages/Footage/uploadFootage';

import CreateAccount from './pages/Onboarding/createAccount';
import EmailVerification from './pages/Onboarding/emailVerification';
import EnterEmail from './pages/Onboarding/enterEmail';
import ForgotPassword from './pages/Onboarding/forgotPassword';
import Login from './pages/Onboarding/login';
import ResetPassword from './pages/Onboarding/resetPassword';

import Pricing from './pages/Pricing/pricing';

import BillingDetails from './pages/SetupAccount/billingDetails';
import WorkspaceDetails from './pages/SetupAccount/workspaceDetails';

import ShareHub from './pages/Share/shareHub';
import ShareVideo from './pages/Share/shareVideo';

import UploadFaces from './pages/UploadFaces/UploadFaces';
import VideoUpload from './pages/VideoUpload/VideoUpload';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import './styles/form.css';
import './styles/projectBuilder.css';

function App() {
  // Commented out Vic redirecting part for now

  // const user = JSON.parse(localStorage.getItem("user"));
  // let projectBuilderStage = localStorage.getItem("projectBuilderStage");
  let pathname = window.location.pathname;

  // if (
  //     !user &&
  //     [
  //         "/",
  //         "/billing-details",
  //         "/workspace-details",
  //         "/upload-footage",
  //         "/confirm-faces",
  //         "/generate-videos",
  //     ].includes(pathname)
  // ) {
  //     goTo("/enter-email");
  // } else if (user && !pathname.includes("/share") && !pathname.includes("/help")) {
  // if (
  //     !projectBuilderStage ||
  //     ![
  //         "/billing-details",
  //         "/workspace-details",
  //         "/upload-footage",
  //         "/confirm-faces",
  //         "/generate-videos",
  //     ].includes(projectBuilderStage)
  // ) {
  //     localStorage.setItem("projectBuilderStage", "/billing-details");
  //     goTo("/billing-details");
  // } else if (pathname !== projectBuilderStage) goTo(projectBuilderStage);
  // }

  const isSideNavExtendedState = JSON.parse(localStorage.getItem('isSideNav'));

  const [isSideNav, setIsSideNavExtended] = useState(isSideNavExtendedState);

  const toggleSideNav = () => {
    setIsSideNavExtended(!isSideNav);
    localStorage.setItem('isSideNav', !isSideNav);
  };

  return (
    <div className={`app ${isSideNav ? 'with-side-nav' : ''}`}>
      {!pathname.includes('/share') && <Header onClickIcon={toggleSideNav} />}

      <Routes>
        <Route path="/" element={<EnterEmail />} />

        {/* onboarding */}
        <Route path="/enter-email" element={<EnterEmail />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* set up */}
        <Route path="/billing-details" element={<BillingDetails />} />
        <Route
          path="/workspace-details"
          element={
            <ProtectedRoute>
              <WorkspaceDetails isSideNav={isSideNav} />
            </ProtectedRoute>
          }
        />
        {/* project builder upload */}
        <Route
          path="/video-upload"
          element={
            <ProtectedRoute>
              <VideoUpload isSideNav={isSideNav} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-faces"
          element={
            <ProtectedRoute>
              <UploadFaces isSideNav={isSideNav} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-footage"
          element={
            <ProtectedRoute>
              <UploadFootage isSideNav={isSideNav} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirm-faces"
          element={
            <ProtectedRoute>
              <ConfirmFaces isSideNav={isSideNav} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/generate-videos"
          element={
            <ProtectedRoute>
              <GenerateVideos isSideNav={isSideNav} />
            </ProtectedRoute>
          }
        />

        {/* share pages */}
        <Route path="/share/:workspaceId" element={<ShareHub />} />
        <Route path="/share/:workspaceId/:faceId" element={<ShareVideo />} />

        {/* check in */}
        <Route path="/events/:workspaceId" element={<EventCheckIn />} />
        <Route
          path="/ambassador/events/:workspaceId"
          element={<AmbassadorEventCheckIn />}
        />

        <Route path="/brand" element={<Brand isSideNav={isSideNav} />} />

        <Route
          path="/analytics"
          element={<Analytics isSideNav={isSideNav} />}
        />

        <Route path="/pricing" element={<Pricing />} />
      </Routes>

      <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} />
    </div>
  );
}

export default App;
