import React from 'react';
import { Spinner, Button, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { goTo, validateEmail, validatePassword, sendEmail } from '../../Utils';
import ContentHeader from '../../components/contentHeader';
import EmailField from '../../components/emailField';
import PasswordField from '../../components/passwordField';
import { LINK_EXPIRE_IN_MIN } from '../../constants';

import './onboarding.css';

import { createUser } from '../../services/api/auth';
import { signIn } from '../../services/api/sessions';

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      isToSAgreed: false,
      showExpModal: false,
      resendEmail: '',
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.onSubmitResendEmail = this.onSubmitResendEmail.bind(this);
  }

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get('id');

    if (!id) {
      goTo('/');
    } else {
      const emailSends = JSON.parse(localStorage.getItem('emailSends'));
      let foundEmailSend = emailSends.filter((eSend) => eSend.id === id)[0];

      if (!foundEmailSend) goTo('/');
      else if (new Date() > new Date(foundEmailSend.expiryMoment))
        this.setState({
          showExpModal: true,
          resendEmail: foundEmailSend.email,
        });
      else this.setState({ email: foundEmailSend.email });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  onCheckChange() {
    this.setState({ isToSAgreed: !this.state.isToSAgreed });
  }

  onSubmit(e) {
    e.preventDefault();

    const { password, confirmPassword, errors } = this.state;

    let { passwordErr, confirmPasswordErr } = validatePassword(
      password,
      confirmPassword,
    );
    if (passwordErr || confirmPasswordErr) {
      errors.passwordErr = passwordErr;
      errors.confirmPasswordErr = confirmPasswordErr;
      this.setState({ errors });
    }
  }

  onSubmitResendEmail(e) {
    e.preventDefault();

    const { resendEmail, errors } = this.state;

    let emailErr = validateEmail(resendEmail);
    if (emailErr) {
      errors.resendEmailErr = emailErr;
      this.setState({ errors });
    } else {
      sendEmail(resendEmail, 'verification');
      goTo('/email-verification');
    }
  }

  createAccount() {
    const { email, password } = this.state;

    // TODO: change type to workspace-owner when API privilege level allows
    let newUser = {
      email,
      password,
      roles: [{ type: 'admin', name: null, key: null, value: null }],
    };

    this.setState({ isLoading: true });

    // creates new user and logs them in
    createUser(newUser)
      .then(() => {
        signIn(email, password, false)
          .then(() => {
            goTo('/workspace-details');
            this.setState({ isLoading: false });
          })
          .catch(() => {
            toast.error('Something went wrong. Try again later');
            this.setState({ isLoading: false });
          });
      })
      .catch((err) => {
        toast.error('Something went wrong. Try again later');
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      email,
      password,
      confirmPassword,
      isToSAgreed,
      showExpModal,
      resendEmail,
      errors,
      isLoading,
    } = this.state;

    const queryParameters = new URLSearchParams(window.location.search);
    const origin = queryParameters.get('origin');
    let descriptionMsg = '';
    if (!showExpModal) {
      switch (origin) {
        case 'verification_email':
          descriptionMsg = 'Thank you for confirming your email. ';
          break;
        case 'workspace_invitation':
          descriptionMsg = 'Thank you for activating your account. ';
          break;
        default:
          break;
      }
    }

    return (
      <div className="page-container">
        <ContentHeader
          title="Creating your account"
          subtitle={`${descriptionMsg}Let's set a password for your new account.`}
        />

        <div className="page-content">
          <Form noValidate onSubmit={this.onSubmit}>
            <fieldset disabled={isLoading}>
              <EmailField value={email} readOnly={true} disabled={true} />

              <PasswordField
                label="Password"
                name="password"
                value={password}
                isInvalid={errors?.passwordErr}
                errMsg={errors?.passwordErr}
                onChange={(e) => this.onChange(e)}
              />

              <PasswordField
                label="Confirm password"
                name="confirmPassword"
                value={confirmPassword}
                isInvalid={errors?.confirmPasswordErr}
                errMsg={errors?.confirmPasswordErr}
                hideText={true}
                onChange={(e) => this.onChange(e)}
              />
              <Form.Check id="check-api-checkbox">
                <Form.Check.Input
                  value={isToSAgreed}
                  onChange={this.onCheckChange}
                />
                <Form.Check.Label>
                  Accept{' '}
                  <a
                    href="https://my.crowdclip.com/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </Form.Check.Label>
              </Form.Check>
              <Button
                className="submit-loading-btn"
                type="submit"
                onClick={this.createAccount}
                disabled={!isToSAgreed || Object.keys(errors).length > 0}
              >
                <div className={`${!isLoading}`}>Create Account</div>
                <Spinner
                  className={`${isLoading}`}
                  animation="border"
                  role="status"
                />
              </Button>
            </fieldset>
          </Form>

          <div>
            Already have an account? <Link to="/login">Log in</Link>
          </div>
        </div>

        <Modal className="custom-modal" show={showExpModal} size="lg" centered>
          <Modal.Header>
            <Modal.Title>Link has expired</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Verification link expires after {LINK_EXPIRE_IN_MIN} minutes. But
              don't worry, we can send the link again.
            </div>
            <Form noValidate onSubmit={this.onSubmitResendEmail}>
              <EmailField
                name="resendEmail"
                value={resendEmail}
                isInvalid={errors?.resendEmailErr}
                errMsg={errors?.resendEmailErr}
                onChange={(e) => this.onChange(e)}
              />
              <div className="note">
                By clicking 'Resend email' you give consent to send email to the
                provided email address.
              </div>
              <Button type="submit">Resend email</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CreateAccount;
