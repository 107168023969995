import React, { useState } from 'react';
import { Upload } from 'antd';
import { Button, Form } from 'react-bootstrap';
import { BsCloudUpload } from 'react-icons/bs';

import { isEmpty, getBase64 } from '../../Utils';

import ContentHeader from '../../components/contentHeader';
import SideNav from '../../components/sideNav/sideNav';

import './brand.css';

const Brand = (props) => {
  const { isSideNav } = props;

  const brandLogo = JSON.parse(localStorage.getItem('brandLogo'));

  const [logo, setLogo] = useState(isEmpty(brandLogo) ? {} : brandLogo);
  const [isLogoUpload, setIsLogoUpload] = useState(isEmpty(brandLogo));
  const [isUseLogoCheck, setIsUseLogoCheck] = useState(true);

  const onChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      const logo = { name: info.file.name, url: url };

      setLogo(logo);
      localStorage.setItem('brandLogo', JSON.stringify(logo));
      setIsLogoUpload(false);
    });
  };

  const onRemoveLogo = () => {
    setLogo({});
    localStorage.setItem('brandLogo', '{}');
    setIsLogoUpload(true);
  };

  return (
    <div className="page-container with-side-nav no-footer">
      {isSideNav && <SideNav />}

      <ContentHeader
        title="Brand"
        subtitle="Upload a logo to overlay on your creations"
        noIcons={true}
      />

      <div className="page-content">
        {isLogoUpload ? (
          <div className="page-block">
            {!isEmpty(logo) && (
              <div className="logo-preview-text">
                <b>Current logo</b>: {logo.name}
                <img src={logo.url} alt="Logo" className="logo-preview" />
              </div>
            )}

            <Upload.Dragger
              accept="image/*"
              multiple={false}
              onChange={(info) => onChange(info)}
            >
              <BsCloudUpload className="upload-icon" size={80} />
              <div>Drag and drop or click to upload</div>
            </Upload.Dragger>
          </div>
        ) : (
          <div className="page-block">
            <Form.Check id="custom-switch" type="switch">
              <Form.Check.Input
                checked={isUseLogoCheck}
                onChange={() => setIsUseLogoCheck(!isUseLogoCheck)}
              />
              <Form.Check.Label>Use my logo</Form.Check.Label>
            </Form.Check>

            <div className="brand-logo">
              <img src={logo.url} alt="Logo" />
            </div>
          </div>
        )}

        {!isEmpty(logo) && (
          <div className="btns-container">
            <Button variant="secondary" onClick={onRemoveLogo}>
              Remove Logo
            </Button>
            {isLogoUpload ? (
              <Button onClick={() => setIsLogoUpload(false)}>Cancel</Button>
            ) : (
              <Button onClick={() => setIsLogoUpload(true)}>Change Logo</Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Brand;
