import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { upload: baseUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);
const apiKeyClient = getApiKeyClient(baseUrl);

export const privateUpload = async (formData) => {
  try {
    await accessTokenClient.post('api/upload', formData);
    console.log('File uploaded');
  } catch (err) {
    throw new Error(`Error uploading file: ${err.message}`);
  }
};

export const publicUpload = async (formData) => {
  try {
    await apiKeyClient.post('api/upload', formData);
    console.log('File uploaded');
  } catch (err) {
    throw new Error(`Error uploading file: ${err.message}`);
  }
};

export const privateUploadWithInfo = async (formData, info) => {
  try {
    const res = await accessTokenClient.post('api/upload', formData);
    info.onSuccess(res.data, info.file);
    console.log('upload file success!');
  } catch (err) {
    throw new Error(`Error uploading file: ${err.message}`);
  }
};

export const publicUploadWithInfo = async (formData, info) => {
  try {
    const res = await apiKeyClient.post('api/upload', formData);
    info.onSuccess(res.data, info.file);
    console.log('File uploaded');
  } catch (err) {
    throw new Error(`Error uploading file: ${err.message}`);
  }
};
