import React from 'react';
import { Spinner, Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { goTo, validateEmail, validatePassword, sendEmail } from '../../Utils';
import ContentHeader from '../../components/contentHeader';
import EmailField from '../../components/emailField';
import PasswordField from '../../components/passwordField';
import { LINK_EXPIRE_IN_MIN } from '../../constants';

import './onboarding.css';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      showExpModal: false,
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmitResendEmail = this.onSubmitResendEmail.bind(this);
  }

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get('id');

    if (!id) {
      goTo('/');
    } else {
      const emailSends = JSON.parse(localStorage.getItem('emailSends'));
      let foundEmailSend = emailSends.filter((eSend) => eSend.id === id)[0];

      if (!foundEmailSend) goTo('/');
      else if (new Date() > new Date(foundEmailSend.expiryMoment))
        this.setState({ showExpModal: true, email: foundEmailSend.email });
      else this.setState({ email: foundEmailSend.email });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  onSubmit(e) {
    e.preventDefault();

    const { password, confirmPassword, errors } = this.state;

    let { passwordErr, confirmPasswordErr } = validatePassword(
      password,
      confirmPassword,
    );
    if (passwordErr || confirmPasswordErr) {
      errors.passwordErr = passwordErr;
      errors.confirmPasswordErr = confirmPasswordErr;
      this.setState({ errors });
    } else {
      this.setState({ isLoading: true });

      toast.info('API is not connected yet, so nothing happens');

      this.setState({ isLoading: false });

      // do nothing for now, need API to update pw in DB

      // if (success)  {
      //     this.setState({ isLoading: false });
      //     goTo("/login");
      // } else {
      //     toast.error("Something went wrong");
      //     this.setState({ isLoading: false });
      // }
    }
  }

  onSubmitResendEmail(e) {
    e.preventDefault();

    const { email, errors } = this.state;

    let emailErr = validateEmail(email);
    if (emailErr) {
      errors.resendEmailErr = emailErr;
      this.setState({ errors });
    } else {
      sendEmail(email, 'password-reset');
      goTo('/forgot-password');
    }
  }

  render() {
    const {
      email,
      password,
      confirmPassword,
      showExpModal,
      errors,
      isLoading,
    } = this.state;

    return (
      <div className="page-container">
        <ContentHeader
          title="Reset your password"
          subtitle="Set the new password for your account."
        />

        <div className="page-content">
          <Form noValidate onSubmit={this.onSubmit}>
            <fieldset disabled={isLoading}>
              <PasswordField
                label="New password"
                name="password"
                value={password}
                isInvalid={errors?.passwordErr}
                errMsg={errors?.passwordErr}
                onChange={(e) => this.onChange(e)}
              />

              <PasswordField
                label="Confirm new password"
                name="confirmPassword"
                value={confirmPassword}
                isInvalid={errors?.confirmPasswordErr}
                errMsg={errors?.confirmPasswordErr}
                hideText={true}
                onChange={(e) => this.onChange(e)}
              />

              <Button className="submit-loading-btn" type="submit">
                <div className={`${!isLoading}`}>Reset Password</div>
                <Spinner
                  className={`${isLoading}`}
                  animation="border"
                  role="status"
                />
              </Button>
            </fieldset>
          </Form>
        </div>

        <Modal className="custom-modal" show={showExpModal} size="lg" centered>
          <Modal.Header>
            <Modal.Title>Link has expired</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Reset password link expires after {LINK_EXPIRE_IN_MIN} minutes.
              But don't worry, we can send the link again.
            </div>
            <Form noValidate onSubmit={this.onSubmitResendEmail}>
              <EmailField
                value={email}
                isInvalid={errors?.resendEmailErr}
                errMsg={errors?.resendEmailErr}
                onChange={(e) => this.onChange(e)}
              />
              <div className="note">
                By clicking 'Resend email' you give consent to send email to the
                provided email address.
              </div>
              <Button type="submit">Resend email</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ResetPassword;
