import { publicFaceCreate } from './api/faces';
import { uploadFile } from './uploadFileService';

export const checkIn = async (workspaceId, name, photo, videos) => {
  // create face
  const parentElementId = await publicFaceCreate(name, workspaceId);

  // save and upload face
  await uploadFile({
    file: photo.originFileObj,
    workspaceId,
    parentElementId,
    isPublic: true,
  });

  // save and upload UGC
  videos.forEach((video) => {
    uploadFile({
      file: video.originFileObj,
      workspaceId,
      fileKind: 'video-footage',
      isPublic: true,
    });
  });
};

export const ambassadorCheckIn = async (workspaceId, videos) => {
  // save and upload UGC
  videos.forEach((video) => {
    uploadFile({
      file: video.originFileObj,
      workspaceId,
      fileKind: 'video-footage',
      isPublic: true,
    });
  });
};
