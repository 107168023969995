import React from 'react';

class ShareFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div class="share-footer">
        <div>
          This video was automatically curated using CrowdClip's automated AI
          video editing software.
        </div>
        <div>
          If you would like to create your own premium video content at the
          click of a button, simply visit{' '}
          <a href="https://crowdclip.com" target="_blank" rel="noreferrer">
            www.crowdclip.com
          </a>
          .
        </div>

        <div className="socials">
          <a
            href="https://www.instagram.com/crowdclip/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/27a34d6d-b3ef-40b0-9d89-aaaba1cc7674/256x253.png"
              alt="Instagram Icon"
            />
          </a>
          <a
            href="https://www.facebook.com/crowdnclip/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/f4934c0e-cddf-451a-a2f7-1213e8aa8959/256x256.png"
              alt="Facebook Icon"
            />
          </a>
          <a
            href="https://twitter.com/crowdclip/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/4d1b66cb-62fd-44bd-903d-6ee4b8358865/256x256.png"
              alt="Twitter Icon"
            />
          </a>
          <a
            href="https://au.linkedin.com/company/crowdclip"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/1a9a4fa0-73cc-40e9-bf7e-21de98bc7ca0/256x256.png"
              alt="LinkedIn Icon"
            />
          </a>
        </div>

        <hr />

        <div style={{ fontStyle: 'italic' }}>
          Copyright &copy; 2023 CrowdClip, All Rights Reserved
        </div>
      </div>
    );
  }
}

export default ShareFooter;
