import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const FacePreviewModal = ({
  imgModal,
  toggleImgModal,
  clickedImage,
  ...args
}) => {
  const utcTime = clickedImage?.created;

  const date = new Date(utcTime);
  const options = { timeZone: 'Australia/Sydney' };
  const australiaTime = date.toLocaleString('en-AU', options);

  return (
    <Modal isOpen={imgModal} toggle={toggleImgModal} {...args} centered>
      <ModalHeader toggle={toggleImgModal}>
        Face Preview - {clickedImage?.name}
      </ModalHeader>
      <ModalBody className="mx-auto">
        {/* <div>{clickedImage.name}</div> */}
        <img
          src={clickedImage?.img}
          alt="Face Preview"
          className="thumbnail-preview"
        />
      </ModalBody>
      <ModalFooter className="justify-content-center">
        Created on {australiaTime}
      </ModalFooter>
    </Modal>
  );
};

export default FacePreviewModal;
