import config from '../../config';
import { getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { auth: baseUrl },
} = config;

const apiKeyClient = getApiKeyClient(baseUrl);

export const checkEmailAvailability = async (email) => {
  try {
    const response = await apiKeyClient.post('api/available', { email });
    return response.data.availability;
  } catch (err) {
    throw new Error(`Error checking email availability: ${err.message}`);
  }
};

export const createUser = async (newUser) => {
  try {
    await apiKeyClient.post('api/user', newUser);
  } catch (err) {
    throw new Error(
      `Error creating new user: ${err.response.data.message || err.message}`,
    );
  }
};
