import config from '../../config';
import { getAccessTokenClient, getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { elements: baseUrl },
} = config;

const accessTokenClient = getAccessTokenClient(baseUrl);
const apiKeyClient = getApiKeyClient(baseUrl);

export const privateFileCreate = async (files) => {
  try {
    await accessTokenClient.put('api/files', JSON.stringify({ files }), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('File saved');
  } catch (err) {
    throw new Error(`Error saving file: ${err.message}`);
  }
};

export const publicFileCreate = async (files) => {
  try {
    await apiKeyClient.put('api/files', JSON.stringify({ files }), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log('File saved');
  } catch (err) {
    throw new Error(`Error saving file: ${err.message}`);
  }
};
