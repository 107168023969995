import React from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import {
  BsPersonCircle,
  BsChevronDown,
  BsBarChart,
  BsPeople,
  BsPersonCheck,
  BsCalendarEvent,
  BsFilePerson,
  BsPersonVideo,
  BsPersonWorkspace,
  BsBoxArrowLeft,
  BsCollection,
} from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

import { changeWorkspace } from '../../services/changeWorkspace';

import EnabledIcon from '../icons/enabledIcon';
import PremiumIcon from '../icons/premiumIcon';
import PopoverTrigger from '../popoverTrigger';
import SideNavBtn from './sideNavBtn';

const SideNav = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userWorkspaces = JSON.parse(localStorage.getItem('userWorkspaces'));
  const currentWorkspace = JSON.parse(localStorage.getItem('currentWorkspace'));
  const generationStatus = localStorage.getItem('generationStatus');

  const isFreemium = user.userType === 'freemium';

  // allow creating workspace if user is premium or has no workspace yet
  const allowCreateWS = !isFreemium || !currentWorkspace;

  const navigate = useNavigate();

  const onClickChangeWS = async (workspaceId) => {
    await changeWorkspace(workspaceId);
    navigate('/video-upload');
    window.location.reload();
  };

  const onClickCreateWS = () => {
    if (allowCreateWS) navigate('/workspace-details');
    else navigate('/pricing#events-packs');
  };

  const onClickLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
  };

  const renderEasyLinks = () => {
    return (
      <div className="easy-links">
        <div>Your Assets:</div>

        <SideNavBtn icon={<BsBarChart />} text="Analytics" link="/analytics" />

        {/* show link to hub page if videos are generating/generated */}
        {generationStatus && (
          <React.Fragment>
            <PopoverTrigger
              content={
                <SideNavBtn
                  icon={<BsPeople />}
                  text="Hub Page"
                  link={`/share/${currentWorkspace.workspaceId}`}
                />
              }
              placement="bottom"
              hidePopover={generationStatus === 'completed'}
              popoverHeader="Some videos are still generating"
              popoverBody="These videos will appear on the page once complete generating."
            />
          </React.Fragment>
        )}

        {/* if face check-in enabled – show link to community check-in page */}
        {user.enableFacesCheckIn && (
          <SideNavBtn
            icon={<BsPersonCheck />}
            text="Community Check-In"
            link={`/events/${currentWorkspace.workspaceId}`}
          />
        )}

        {/* if ugc enabled – show link to ambassador check-in page */}
        {user.enableUGC && (
          <SideNavBtn
            icon={<BsPersonCheck />}
            text="Ambassador Check-In"
            link={`/ambassador/events/${currentWorkspace.workspaceId}`}
          />
        )}

        <hr />

        <div>CrowdClip Store:</div>

        {user.enableFacesCheckIn && (
          <SideNavBtn
            icon={<BsFilePerson />}
            text="Face Check-In Enabled"
            extraIcon={<EnabledIcon />}
            isBtnImitator={true}
          />
        )}

        {user.enableUGC && (
          <SideNavBtn
            icon={<BsPersonVideo />}
            text="UGC Enabled"
            extraIcon={<EnabledIcon />}
            isBtnImitator={true}
          />
        )}

        <SideNavBtn
          icon={<BsCalendarEvent />}
          text="Events Packs"
          link="/pricing#events-packs"
        />

        {!user.enableFacesCheckIn && (
          <SideNavBtn
            icon={<BsFilePerson />}
            text="Purchase Face Check-In"
            link="/pricing#events-packs"
            extraIcon={<PremiumIcon withCircle={true} />}
          />
        )}

        {!user.enableUGC && (
          <SideNavBtn
            icon={<BsPersonVideo />}
            text="Purchase UGC"
            link="/pricing#events-packs"
            extraIcon={<PremiumIcon withCircle={true} />}
          />
        )}
      </div>
    );
  };

  return (
    <div className="side-nav">
      <div className="top-block">
        <div className="user-block">
          <div>
            <div className="user-email">{user.email}</div>
            <div className="user-type note">{user.userType}</div>
          </div>
          <BsPersonCircle size={40} />
        </div>

        {currentWorkspace && (
          <DropdownButton
            title={
              <div className="text-with-icon">
                {currentWorkspace.name}
                <BsChevronDown size="1.1em" />
              </div>
            }
            variant="outline"
            className="workspace-btn"
          >
            <Dropdown.Header>Switch to another workspace</Dropdown.Header>
            <Dropdown.Divider />
            {userWorkspaces.map((workspace) => {
              return (
                <Dropdown.Item
                  onClick={() => onClickChangeWS(workspace.workspaceId)}
                  active={
                    workspace.workspaceId === currentWorkspace.workspaceId
                  }
                >
                  {workspace.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        )}

        {isFreemium && (
          <Link
            to="/pricing"
            target="_blank"
            className="btn btn-primary text-with-icon"
          >
            <PremiumIcon />
            <div>Try Premium</div>
          </Link>
        )}
      </div>

      {/* if workspace selected – render easy links block */}
      {currentWorkspace && renderEasyLinks()}

      <div className="bottom-block">
        <SideNavBtn
          icon={<BsCollection />}
          text="Brand"
          link="/brand"
          extraIcon={isFreemium && <PremiumIcon withCircle={true} />}
        />

        <Button variant="clear" onClick={onClickCreateWS}>
          <div className="text-with-icon">
            <BsPersonWorkspace size="1.1em" />
            <div>Create Workspace</div>
          </div>
          {!allowCreateWS && <PremiumIcon withCircle={true} />}
        </Button>

        <hr />

        <Button variant="clear" onClick={() => onClickLogOut()}>
          <div className="text-with-icon">
            <BsBoxArrowLeft />
            <div>Log Out</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SideNav;
