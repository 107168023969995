import React from 'react';
import moment from 'moment';
import { Spinner, Button, Modal } from 'react-bootstrap';

import Logo from '../../components/logo';
import ShareFooter from '../../components/shareFooter';

import { getOneCreation } from '../../services/api/creations';
import { getWorkspaceName } from '../../services/api/workspaces';

import withRouter from '../../withRouter';

import '../../styles/share.css';

class ShareVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      face: {},
      workspaceName: 'Workspace',
      isLoading: true,
      videoLoading: false,
      isShareModalOpen: false,
    };
  }

  async componentDidMount() {
    const { workspaceId, faceId } = this.props.params;

    const face = await getOneCreation(faceId);
    const workspaceName = await getWorkspaceName(workspaceId);

    this.setState({ face, workspaceName, isLoading: false });
  }

  handleDownload = async () => {
    const face = this.state.face;

    try {
      const videoUrl = `https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/${face.elementId}/${face.name}`;
      fetch(videoUrl)
        .then((response) => {
          this.setState((prevState) => ({ ...prevState, videoLoading: true }));
          return response.blob();
        })
        .then(async (blob) => {
          this.setState((prevState) => ({ ...prevState, videoLoading: false }));
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${face.name.split('@')[1].split('.')[0]} video.mp4`;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      console.error(error);
    }
  };

  handleShare = () => {
    const { face } = this.state;
    const videoUrl = `https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/${face.elementId}/${face.name}`;

    if (navigator.share) {
      navigator
        .share({
          title: `${face.name.split('@')[1].split('.')[0]} video.mp4`,
          text: 'Check out this video',
          url: videoUrl,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    }
  };

  handleShareModal = () => {
    this.setState({ isShareModalOpen: true });
  };

  render() {
    const { face, workspaceName, isLoading, videoLoading, isShareModalOpen } =
      this.state;

    const isMobile = window.innerWidth < 480;

    if (isLoading) {
      return (
        <div className="page-spinner">
          <Spinner animation="border" role="status" />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="page-container share-page">
          <Logo withTagLine={true} />

          <h2>
            <b>{face.name.split('@')[1].split('.')[0]}</b> at {workspaceName}
          </h2>

          <video
            className="video-player"
            src={`https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/${face.elementId}/${face.name}`}
            poster={`https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.thumbnails/${face.frameId}/720x405.png`}
            controls
          ></video>

          <div className="video-details">
            <div>{moment(face.created).fromNow()}</div>
            <Button onClick={this.handleShareModal}>Share</Button>
          </div>
        </div>
        <ShareFooter />
        <Modal
          className="custom-modal"
          show={isShareModalOpen}
          onHide={() => this.setState({ isShareModalOpen: false })}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Video</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            {isMobile ? (
              <>
                <h6>We're excited that you want to share the video 🤩!</h6>
                <h6>
                  Please click the button below to download and share this
                  awesome video 💗!
                </h6>

                <Button onClick={this.handleShare}>
                  {videoLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    `Share`
                  )}
                </Button>
              </>
            ) : (
              <>
                <h5>Oops, 🥹 your browser doesn't support sharing.</h5>
                <h5>Please download the file and share it manually.</h5>

                <Button onClick={this.handleDownload}>
                  {videoLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    `Download`
                  )}
                </Button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(ShareVideo);
