import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BsCalendarEvent, BsFileBarGraph, BsCheckLg } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import './pricing.css';

import { isEmpty } from '../../Utils';

const Pricing = () => {
  const [eventSelected, setEventSelected] = useState(null);
  const [reportSelected, setReportSelected] = useState(null);

  useEffect(() => {
    const elementId = window.location.hash.substring(1); // Remove the leading '#' from the URL hash
    scrollToElement(elementId);
  });

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) element.scrollIntoView();
  };

  const features = [
    [
      '500MB storage',
      '750MB storage',
      '1GB storage',
      '3GB storage',
      '400GB to 1TB storage, unlimited uploads*',
    ],
    ['1 Login', '2 Logins', '3 Logins', '5 Logins', 'Up to 75 Logins*'],
    [
      '1 Profile per social platform',
      '1 Profile per social platform',
      '2 Profiles per social platform',
      '20 Profiles per social platform',
      '75 Profiles per social platform',
    ],
    [
      'No video resizing',
      '15 video resizes/month',
      '30 video resizes/month',
      'Unlimited resizing',
      'Unlimited resizing',
    ],
    [
      '30 posts/platform/month',
      '100 posts/platform/month',
      '250 posts/platform/month',
      'Unlimited posts/platform',
      'Unlimited posts/platform',
    ],
    [
      '1080p resolution output',
      '1080p resolution output',
      '1080p resolution output',
      '4K resolution output*',
      '4K resolution output*',
    ],
    [
      '60 second clip limit',
      '60 second clip limit',
      '60 second clip limit',
      'Unlimited length clips',
      'Unlimited length clips',
    ],
    [
      'Custom social captions',
      'Custom social captions',
      'Logos and testimonials',
      'Logos and testimonials',
      'Logos and testimonials',
    ],
    [
      'Interact with player videos',
      'Interact with player videos',
      'Custom social captions',
      'Scheduled video posting',
      'Scheduled video posting',
    ],
    [
      'Interact with attendee videos',
      'Interact with attendee videos',
      'Interact with player videos',
      'Customise AI accuracy',
      'Full AI accuracy',
    ],
    [
      'Share your UGC',
      'Share your UGC',
      'Interact with attendee videos',
      'Sub-video views distribution report',
      '70+ sub-videos created',
    ],
    [
      '',
      '',
      'Share your UGC',
      'Track sponsor value generated analytics',
      'Unlimited users',
    ],
    ['', '', '', 'Resizing and templates', 'API integration'],
    ['', '', '', 'Priority support', 'Unlimited video distribution*'],
    ['', '', '', 'Personalised videos emailed', 'Business/partner reports'],
    [
      '',
      '',
      '',
      'Invite sponsors and partners',
      'Create/increase your own advertisement features',
    ],
    ['', '', '', '', 'Automatic distribution'],
    ['', '', '', '', 'Personalised videos texted'],
    ['', '', '', '', 'Create your own video market place'],
    ['', '', '', '', 'Drive back to owned properties'],
    ['', '', '', '', 'Become a crowd video distribution company'],
    ['', '', '', '', 'Embeddable players with wordpress plugin'],
    ['', '', '', '', 'Whitelabel fan website'],
    ['', '', '', '', 'Team content moderation'],
  ];

  const eventsPack = [
    {
      title: 'Single Event',
      multiplier: 1,
      price: '1 999',
      features: [
        'Access up to 1 Events',
        '150 Personalised Videos',
        'Custom Event Builder',
      ],
      extraFeatures: [
        { name: 'UGC Collection', price: '299' },
        { name: 'Personalised Email Template', price: '199' },
      ],
    },
    {
      title: '2 Events',
      multiplier: 2,
      price: '3 699',
      features: [
        'Access up to 2 Events',
        '150 Personalised Videos x2',
        'Custom Event Builder',
      ],
      extraFeatures: [
        { name: 'UGC Collection', price: '569' },
        { name: 'Personalised Email Template', price: '369' },
      ],
    },
    {
      title: '4 Events',
      multiplier: 4,
      price: '6 999',
      features: [
        'Access up to 4 Events',
        '150 Personalised Videos x4',
        'Custom Event Builder',
      ],
      extraFeatures: [
        { name: 'UGC Collection', price: '799' },
        { name: 'Personalised Email Template', price: '499' },
      ],
    },
  ];

  const reportsPack = [
    {
      title: 'Single Report',
      multiplier: 1,
      price: '39.99',
      features: ['Detailed campaign analysis for 1 event'],
    },
    {
      title: '3 Reports',
      multiplier: 3,
      price: '95.99',
      features: ['Detailed campaign analysis for 3 events'],
    },
    {
      title: '5 Reports',
      multiplier: 5,
      price: '139.99',
      features: ['Detailed campaign analysis for 5 events'],
    },
  ];

  const getPacksConfig = (type) => {
    switch (type) {
      case 'events':
        return {
          pack: eventsPack,
          icon: <BsCalendarEvent />,
          selectedItem: eventSelected,
          setSelectedItem: setEventSelected,
        };
      case 'reports':
        return {
          pack: reportsPack,
          icon: <BsFileBarGraph />,
          selectedItem: reportSelected,
          setSelectedItem: setReportSelected,
        };
      default:
        return {
          pack: [],
          icon: null,
          selectedItem: -1,
          setSelectedItem: () => {},
        };
    }
  };

  const renderPacksContainer = (type) => {
    const { pack, icon, selectedItem, setSelectedItem } = getPacksConfig(type);

    return (
      <div className="events-container">
        {pack.map((item, index) => {
          const isSelected = selectedItem === index;

          return (
            <div
              className={`event-block ${isSelected ? 'selected' : ''}`}
              onClick={() => setSelectedItem(index)}
            >
              <div className="icon-container">
                <div className="circle-icons-container stack">
                  <div className="circle-icon main">{icon}</div>
                  <div className="circle-icon counter">x{item.multiplier}</div>
                </div>
              </div>

              <h5>{item.title}</h5>

              <div className="event-price">${item.price}</div>

              <div className="features-container">
                <h6>Includes</h6>

                {item.features.map((feature) => {
                  return (
                    <div className="text-with-icon big-icon">
                      <BsCheckLg />
                      <div>{feature}</div>
                    </div>
                  );
                })}
              </div>

              {isSelected && (
                <React.Fragment>
                  {!isEmpty(item.extraFeatures) && (
                    <React.Fragment>
                      <hr />

                      <h6>Add extra features to your pack</h6>

                      <div className="add-ons-container">
                        {item.extraFeatures.map((extraFeature) => {
                          return (
                            <Form.Check id="check-api-checkbox">
                              <Form.Check.Input />
                              <Form.Check.Label>
                                <div className="add-on-title">
                                  {extraFeature.name}
                                </div>
                                <div>+${extraFeature.price}</div>
                              </Form.Check.Label>
                            </Form.Check>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}

                  <Button>Add to Cart</Button>
                </React.Fragment>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="page-container no-footer pricing">
      <div className="content-header">
        <h1 className="heading">CrowdClip Pricing</h1>
        <div>The Future of Video. Explore Your Options.</div>
      </div>

      <div className="page-content">
        <div className="page-block" id="subscription">
          <h4>Subscription</h4>

          <table className="pricing-table">
            <thead>
              <tr>
                <th>FREE</th>
                <th>PLUS +</th>
                <th>FREELANCER PRO</th>
                <th>BUSINESS</th>
                <th>ENTERPRISE</th>
              </tr>
              <tr className="price-row">
                <td>$0.00</td>
                <td>$5.99</td>
                <td>$8.99</td>
                <td>$16.50</td>
                <td>Price on Request</td>
              </tr>
              <tr className="note-row">
                <td>forever</td>
                <td>per user/week</td>
                <td>per user/week</td>
                <td>for __ users/week</td>
                <td>Customisable for your needs</td>
              </tr>
              <tr className="button-row">
                <td>
                  <Button variant="light">Sign Up Free</Button>
                </td>
                <td>
                  <Button variant="light">Buy + Now</Button>
                </td>
                <td>
                  <Button variant="light">Buy Pro Now</Button>
                </td>
                <td>
                  <Button variant="light">Claim Free Trial</Button>
                </td>
                <td>
                  <Button variant="light">Learn More/Demo</Button>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="features-heading">
                <td colSpan={2}></td>
                <td style={{ color: '#f05a5b' }}>Standard Features</td>
                <td>All PRO Features, plus</td>
                <td>All BUSINESS Features, plus</td>
              </tr>
              {features.map((row) => {
                return (
                  <tr>
                    {row.map((cell) => {
                      return <td>{cell}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="page-block" id="events-packs">
          <h4>Events Packs</h4>

          <div>Purchase in bulk for volume discount.</div>

          {renderPacksContainer('events')}

          <div className="note">
            Need more events? <Link>Contact Us</Link>
          </div>
        </div>

        <div className="page-block" id="reports-packs">
          <h4>Reports Packs</h4>

          <div>Purchase in bulk for volume discount.</div>

          {renderPacksContainer('reports')}

          <div className="note">
            Need more reports? <Link>Contact Us</Link>
          </div>
        </div>

        <div className="page-block">
          <hr />

          <div className="pricing-footer">
            <div className="note">Prices in $USD. * Beta Paid Trial Prices</div>
            <div className="note bold">
              Feeling extra creative? Reach out to us for a custom plan!
              <br />
              sales@crowdclip.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
