import React from 'react';
import { Spinner, Button, Form, FloatingLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { isEmpty, goTo } from '../../Utils.js';
import ContentHeader from '../../components/contentHeader';
import SideNav from '../../components/sideNav/sideNav';
import { WORKSPACE_TYPE_DEFAULT, WORKSPACE_TYPE_OTHER } from '../../constants';

import { createWorkspace } from '../../services/api/workspaces';
import { changeWorkspace } from '../../services/changeWorkspace';

import '../Onboarding/onboarding.css';

class WorkspaceDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      workspaceName: '',
      eventName: '',
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    const currentWorkspace = JSON.parse(
      localStorage.getItem('currentWorkspace'),
    );

    // redirect to pricing if user is freemium and already has workspace
    if (user.userType === 'freemium' && currentWorkspace)
      goTo('/pricing#events-packs');
  }

  onSubmit(e) {
    e.preventDefault();

    const { workspaceName, eventName, errors } = this.state;

    const userWorkspaces = JSON.parse(localStorage.getItem('userWorkspaces'));

    const user = JSON.parse(localStorage.getItem('user'));

    if (workspaceName.trim().length === 0)
      errors.workspaceNameErr = 'Workspace name is required';

    // if (eventName.trim().length === 0)
    //   errors.eventNameErr = 'Event name is required';

    if (Object.keys(errors).length === 0) {
      this.setState({ isLoading: true });

      // first created workspace is default, the rest has type other
      const type = isEmpty(userWorkspaces)
        ? WORKSPACE_TYPE_DEFAULT
        : WORKSPACE_TYPE_OTHER;

      createWorkspace(workspaceName, user.session.userId, type)
        .then(async (workspace) => {
          await changeWorkspace(workspace.workspaceId);
          this.setState({ isLoading: false });
          localStorage.setItem('projectBuilderStage', '/video-upload');
          goTo('/video-upload');
        })
        .catch(() => {
          this.setState({ isLoading: false });
          toast.error('Something went wrong. Try again later');
        });
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const { isSideNav } = this.props;
    const { workspaceName, eventName, errors, isLoading } = this.state;

    return (
      <div className="page-container with-side-nav">
        {isSideNav && <SideNav />}

        <ContentHeader
          title="Enter workspace details"
          subtitle="Provide workspace name to create your video project."
        />

        <div className="page-content">
          <Form noValidate onSubmit={this.onSubmit}>
            <fieldset disabled={isLoading}>
              <FloatingLabel label="Workspace name">
                <Form.Control
                  type="text"
                  name="workspaceName"
                  value={workspaceName}
                  onChange={this.onChange}
                  placeholder="Workspace name"
                  isInvalid={errors?.workspaceNameErr}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.workspaceNameErr}
                </Form.Control.Feedback>
              </FloatingLabel>

              {/* Note: commented out as no events are currently creating  */}
              {/* <FloatingLabel label="Event name">
                <Form.Control
                  type="text"
                  name="eventName"
                  value={eventName}
                  onChange={this.onChange}
                  placeholder="Event name"
                  isInvalid={errors?.eventNameErr}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.eventNameErr}
                </Form.Control.Feedback>
              </FloatingLabel> */}

              <Button className="submit-loading-btn" type="submit">
                <div className={`${!isLoading}`}>Continue</div>
                <Spinner
                  className={`${isLoading}`}
                  animation="border"
                  role="status"
                />
              </Button>
            </fieldset>
          </Form>
        </div>
      </div>
    );
  }
}

export default WorkspaceDetails;
