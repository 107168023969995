import React from 'react';
import parse from 'html-react-parser';
import {
  Spinner,
  Button,
  Form,
  FloatingLabel,
  Row,
  Col,
  InputGroup,
} from 'react-bootstrap';
import {
  FaCreditCard,
  FaCcApplePay,
  FaCcPaypal,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
} from 'react-icons/fa6';
import validator from 'validator';

import { goTo } from '../../Utils.js';
import ContentHeader from '../../components/contentHeader';

import '../Onboarding/onboarding.css';

class BillingDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      suburb: '',
      state: '',
      postcode: '',
      paymentMethod: 'card',
      cardNumber: '',
      cardExpiration: '',
      cardCode: '',
      cardName: '',
      errors: {},
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this);
  }

  onChange(e) {
    if (e.target.name === 'cardExpiration') {
      e.target.value = e.target.value
        .replace(/^([1-9]\/|[2-9])$/g, '0$1/') // 3 > 03/
        .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 > 11/
        .replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 > 01/3
        .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2') // 141 > 01/41
        .replace(/^([0]+)\/|[0]+$/g, '0') // 0/ > 0 and 00 > 0
        .replace(/[^\d/]|^[/]*$/g, '') // To allow only digits and `/`
        .replace(/\/\//g, '/') // Prevent entering more than 1 `/`
        .substring(0, 5); // Limit length to 5 characters
    }

    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  onChangePaymentMethod(value) {
    this.setState({ paymentMethod: value });
  }

  onSubmit(e) {
    e.preventDefault();

    const {
      firstName,
      lastName,
      address,
      address2,
      suburb,
      state,
      postcode,
      paymentMethod,
      cardNumber,
      cardExpiration,
      cardCode,
      cardName,
      errors,
    } = this.state;

    const cardExpirationRegex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    const cardCodeRegex = /^[0-9]{3,4}$/;

    if (firstName.trim().length === 0)
      errors.firstNameErr = 'First name is required';

    if (lastName.trim().length === 0)
      errors.lastNameErr = 'Last name is required';

    if (address.trim().length === 0) errors.addressErr = 'Address is required';

    if (suburb.trim().length === 0) errors.suburbErr = 'Suburb is required';

    if (state === '') errors.stateErr = 'State is required';

    if (postcode.trim().length === 0)
      errors.postcodeErr = 'Postcode is required';
    else if (!validator.isPostalCode(postcode, 'AU'))
      errors.postcodeErr = 'Postcode is invalid';

    if (paymentMethod === 'card') {
      if (cardNumber.trim().length === 0)
        errors.cardNumberErr = 'Card number is required';
      else if (!validator.isCreditCard(cardNumber))
        errors.cardNumberErr = 'Card number is invalid';

      if (cardExpiration.trim().length === 0)
        errors.cardExpirationErr = 'Expiration date is required';
      else if (!cardExpirationRegex.test(cardExpiration))
        errors.cardExpirationErr =
          'Expiration date is invalid. Enter date in format MM/YY';

      if (cardCode.trim().length === 0)
        errors.cardCodeErr = 'Security code is required';
      else if (!cardCodeRegex.test(cardCode))
        errors.cardCodeErr = 'Security code is invalid';

      if (cardName.trim().length === 0) errors.cardNameErr = 'Name is required';
    }

    if (Object.keys(errors).length === 0) {
      let billingDetails = {
        billingAddress: {
          firstName,
          lastName,
          address,
          address2,
          suburb,
          state,
          postcode,
        },
        paymentDetails: {
          paymentMethod,
        },
      };

      if (paymentMethod === 'card')
        billingDetails.paymentDetails.cardDetails = {
          cardNumber,
          cardExpiration,
          cardCode,
          cardName,
        };

      this.setState({ isLoading: true });

      // billing details are only saved in localStorage user for now. need API to save in DB
      setTimeout(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        user.billingDetails = billingDetails;
        localStorage.setItem('user', JSON.stringify(user));
        this.setState({ isLoading: false });

        localStorage.setItem('projectBuilderStage', '/workspace-details');
        goTo('/workspace-details');
      }, 2000);
    } else {
      this.setState({ errors });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      address,
      address2,
      suburb,
      state,
      postcode,
      paymentMethod,
      cardNumber,
      cardExpiration,
      cardCode,
      cardName,
      errors,
      isLoading,
    } = this.state;

    let months = [];
    let years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1; i <= 12; i++) months.push((i < 10 ? '0' : '') + i);
    for (let i = 0; i < 15; i++) years.push((currentYear + i).toString());

    let cardDetailsErr =
      errors?.cardNumberErr || errors?.cardExpirationErr || errors?.cardCodeErr;

    return (
      <div className="page-container">
        <ContentHeader
          title="Setup your account"
          subtitle="Please enter your billing details."
        />

        <div className="page-content">
          <Form noValidate onSubmit={this.onSubmit}>
            <fieldset disabled={isLoading}>
              <h4>Billing address</h4>

              <Row>
                <Col>
                  <FloatingLabel label="First name">
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={this.onChange}
                      placeholder="First name"
                      isInvalid={errors?.firstNameErr}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.firstNameErr}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="Last name">
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={this.onChange}
                      placeholder="Last name"
                      isInvalid={errors?.lastNameErr}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.lastNameErr}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel label="Address">
                <Form.Control
                  type="text"
                  name="address"
                  value={address}
                  onChange={this.onChange}
                  placeholder="1234 Main St"
                  isInvalid={errors?.addressErr}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.addressErr}
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel
                label={parse(
                  "Apartment or suite <span class='text-muted'>(Optional)</span>",
                )}
              >
                <Form.Control
                  type="text"
                  name="address2"
                  value={address2}
                  onChange={this.onChange}
                  placeholder="Apartment or suite"
                />
              </FloatingLabel>
              <Row>
                <Col>
                  <FloatingLabel label="Suburb">
                    <Form.Control
                      type="text"
                      name="suburb"
                      value={suburb}
                      onChange={this.onChange}
                      placeholder="Suburb"
                      isInvalid={errors?.suburbErr}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.suburbErr}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingSelect" label="State">
                    <Form.Select
                      name="state"
                      value={state}
                      onChange={this.onChange}
                      isInvalid={errors?.stateErr}
                      required
                    >
                      <option value="">Select state</option>
                      <option value="nsw">New South Wales</option>
                      <option value="vic">Victoria</option>
                      <option value="qld">Queensland</option>
                      <option value="wa">Western Australia</option>
                      <option value="sa">South Australia</option>
                      <option value="tas">Tasmania</option>
                      <option value="act">Australian Capital Territory</option>
                      <option value="nt">Northern Territory</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors?.stateErr}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="Postcode">
                    <Form.Control
                      type="number"
                      name="postcode"
                      value={postcode}
                      onChange={this.onChange}
                      placeholder="Postcode"
                      isInvalid={errors?.postcodeErr}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.postcodeErr}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <h4 style={{ marginTop: '1.5vw' }}>Payment</h4>

              <Row className="payment-methods-group">
                <Col>
                  <Button
                    variant="form"
                    className={paymentMethod === 'card' ? 'selected' : ''}
                    onClick={() => this.onChangePaymentMethod('card')}
                  >
                    <FaCreditCard />
                    Card
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="form"
                    className={paymentMethod === 'apple-pay' ? 'selected' : ''}
                    onClick={() => this.onChangePaymentMethod('apple-pay')}
                  >
                    <FaCcApplePay />
                    Apple Pay
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="form"
                    className={paymentMethod === 'paypal' ? 'selected' : ''}
                    onClick={() => this.onChangePaymentMethod('paypal')}
                  >
                    <FaCcPaypal />
                    Paypal
                  </Button>
                </Col>
              </Row>

              {paymentMethod === 'card' && (
                <React.Fragment>
                  <Form.Group
                    className={`card-box ${cardDetailsErr ? 'invalid' : ''}`}
                  >
                    <Form.Label>Card details</Form.Label>
                    <InputGroup className="card-num-group">
                      <Form.Control
                        type="text"
                        name="cardNumber"
                        value={cardNumber}
                        onChange={this.onChange}
                        placeholder="1234 1234 1234 1234"
                        required
                      />
                      <InputGroup.Text className="cc-types text-muted">
                        <FaCcVisa />
                        <FaCcMastercard />
                        <FaCcAmex />
                      </InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="card-details-group">
                      <Form.Control
                        type="text"
                        name="cardExpiration"
                        value={cardExpiration}
                        onChange={this.onChange}
                        placeholder="MM / YY"
                        required
                      />
                      <Form.Control
                        type="text"
                        name="cardCode"
                        value={cardCode}
                        onChange={this.onChange}
                        placeholder="CVV / CVC"
                        maxLength={4}
                        required
                      />
                    </InputGroup>
                    <Form.Control.Feedback
                      type="invalid"
                      className={cardDetailsErr ? 'show' : ''}
                    >
                      {cardDetailsErr}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <FloatingLabel label="Name on card">
                    <Form.Control
                      type="text"
                      name="cardName"
                      value={cardName}
                      onChange={this.onChange}
                      placeholder="Name on card"
                      isInvalid={errors?.cardNameErr}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.cardNameErr}
                    </Form.Control.Feedback>
                    <Form.Text muted>Full name as displayed on card</Form.Text>
                  </FloatingLabel>
                </React.Fragment>
              )}

              <Button type="submit">
                <div className={`${!isLoading}`}>Continue</div>
                <Spinner
                  className={`${isLoading}`}
                  animation="border"
                  role="status"
                />
              </Button>
            </fieldset>
          </Form>
        </div>
      </div>
    );
  }
}

export default BillingDetails;
