import React from 'react';
import { Spinner, Button, Form, ProgressBar } from 'react-bootstrap';
import { BsCheckCircleFill, BsCheckLg, BsClockHistory } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { workspaceId, isEmpty, validateEmail } from '../../Utils.js';
import ContentHeader from '../../components/contentHeader';
import EmailField from '../../components/emailField';
import ProjectBuilderStepper from '../../components/projectBuilderStepper';
import SideNav from '../../components/sideNav/sideNav';

import { getFaceGroups } from '../../services/faceGroupsService';

class Hub extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAllGenerated: false,
      completeFaces: [],
      generatedFaces: [],
      isLoading: true,
      emailFeature: false,
    };

    this.onChange = this.onChange.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  async componentDidMount() {
    await this.checkFootageData(true);
  }

  async checkFootageData(didMount) {
    const { generatedFaces, completeFaces } = await getFaceGroups(workspaceId);

    // TODO: redirect to correct page if no videos are generating / generated

    let isAllGenerated = isEmpty(completeFaces);

    this.setState({
      isAllGenerated,
      completeFaces,
      generatedFaces,
      isLoading: false,
    });

    if (!isAllGenerated)
      setTimeout(async () => await this.checkFootageData(), 5000);
    else {
      localStorage.setItem('generationStatus', 'completed');

      if (!didMount)
        toast.success(
          'Congratulations! All personalised videos are generated.',
          { toastId: 'video-generation-success' },
        );
    }
  }

  onChange(e, faceId) {
    const { generatedFaces } = this.state;

    generatedFaces[faceId].email = e.target.value;
    generatedFaces[faceId].emailErr = null;

    this.setState({ generatedFaces });
  }

  checkEmail(e, faceId) {
    e.preventDefault();

    const { generatedFaces } = this.state;

    generatedFaces[faceId].emailErr = validateEmail(
      generatedFaces[faceId].email || '',
    );

    this.setState({ generatedFaces });
  }

  sendEmail(e, faceId) {
    const { generatedFaces } = this.state;

    this.checkEmail(e, faceId);

    let face = generatedFaces[faceId];

    if (!face.emailErr) {
      toast.success(
        <div>
          Sent email to <b>{face.email}</b>
        </div>,
      );

      face.emailStatus = 'sending';
      this.setState({ generatedFaces });

      setTimeout(() => {
        face.emailStatus = 'sent';
        this.setState({ generatedFaces });

        setTimeout(() => {
          face.emailStatus = 'resend';
          this.setState({ generatedFaces });
        }, 2000);
      }, 2000);
    }
  }

  numberWithSpaces(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  renderFaceStats(face) {
    return (
      <div className="face-stats">
        <div className="stats-block">
          <div>Followers</div>
          <div>{this.numberWithSpaces(face.followers) || 'N/A'}</div>
        </div>

        <div className="stats-block">
          <div>Est. Views</div>
          <div>{this.numberWithSpaces(face.views) || 'N/A'}</div>
        </div>

        <div className="stats-block">
          <div>Shared?</div>
          <div className="shared-icon">
            <BsClockHistory />
          </div>
        </div>
      </div>
    );
  }

  // this is temporary solution to show followers & est. views for Harvard demo
  getFaceStats() {
    const { generatedFaces } = this.state;

    let viewsSum = 0;

    const generatedFacesUpd = generatedFaces.map((face) => {
      let letterSum = 0;
      for (let letter of face.name) letterSum += letter.charCodeAt(0);

      const multiplier =
        parseInt(new Date(face.created).getTime().toString().slice(-2)) / 2;

      const followers = Math.round(letterSum * multiplier);
      const views = Math.round(followers * 1.36986);

      viewsSum += parseInt(views);

      face = {
        ...face,
        followers,
        views,
      };

      return face;
    });

    return { viewsSum, generatedFacesUpd };
  }

  render() {
    const { isSideNav } = this.props;
    const {
      isAllGenerated,
      completeFaces,
      generatedFaces,
      isLoading,
      emailFeature,
    } = this.state;

    if (isLoading) {
      return (
        <div className="page-spinner">
          <Spinner animation="border" role="status" />
        </div>
      );
    }

    let progress = Math.round(
      (generatedFaces.length / (completeFaces.length + generatedFaces.length)) *
        100,
    );
    // user.workspaceName.replace(/\s+/g, "-").toLowerCase()
    let hubLink = window.location.origin + '/share/' + workspaceId;

    const { viewsSum, generatedFacesUpd } = this.getFaceStats();

    return (
      <div className="page-container with-side-nav no-footer">
        {isSideNav && <SideNav />}

        <ProjectBuilderStepper activeStep={4} />

        <ContentHeader
          title={
            isAllGenerated
              ? 'Your personalised videos are ready!'
              : 'Generating personalised videos...'
          }
          subtitle={
            !isEmpty(generatedFaces)
              ? 'Assign an email address for a face to send the attendee their personalised video.'
              : 'A little bit of patience and your personalised videos will appear on the page.'
          }
        />

        <div className="page-content">
          <ProgressBar
            className={`video-generation-progress ${
              progress === 0 ? 'empty' : ''
            }`}
            variant="success"
            now={progress}
            label={
              isAllGenerated ? (
                <div className="progress-bar-label">
                  <BsCheckCircleFill />
                  All videos are generated
                </div>
              ) : (
                `${progress}%`
              )
            }
            animated={!isAllGenerated}
          />

          {!isAllGenerated && (
            <div className="page-block">
              <h4>Magic in progress, will be ready soon</h4>
              <div className="faces-container">
                {completeFaces.map((face) => {
                  return (
                    <div className="face-block">
                      <div className="img-overlay">
                        <Spinner animation="border" role="status" />
                      </div>
                      <img
                        src={face.img}
                        className="face-icon"
                        alt={`${face.name} Icon`}
                      />
                      <div className="face-name">{face.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!isEmpty(generatedFaces) && (
            <div className="page-block">
              {isAllGenerated ? (
                <React.Fragment>
                  <h4>All done! Now share the videos</h4>
                  <div>
                    All videos can be shared with the hub link:{' '}
                    <Link to={hubLink} target="_blank">
                      {hubLink}
                    </Link>
                  </div>
                </React.Fragment>
              ) : (
                <h4>Generated personalised videos</h4>
              )}

              <div>
                Total combined views and value across people below ={' '}
                <b>{this.numberWithSpaces(viewsSum)} views</b>. Get nudging them
                to share their personalised clips!
              </div>

              <div className="faces-container with-email">
                {generatedFacesUpd.map((face, faceId) => {
                  // face.name.replace(/\s+/g, "-").toLowerCase();
                  let videoLink = hubLink + '/' + face.elementId;

                  return (
                    <div className="face-block">
                      <img
                        src={face.img}
                        className="face-icon"
                        alt={`${face.name} Icon`}
                      />
                      <div className="face-details">
                        <div className="sub-heading-container">
                          <h5>{face.name}</h5>
                          {this.renderFaceStats(face)}
                        </div>

                        <div>
                          <b>Share link</b>:{' '}
                          <Link to={videoLink} target="_blank">
                            {videoLink}
                          </Link>
                        </div>

                        {emailFeature && (
                          <Form
                            className="inline-form"
                            noValidate
                            onSubmit={(e) => this.checkEmail(e, faceId)}
                          >
                            <fieldset disabled={face.emailStatus === 'sending'}>
                              <EmailField
                                value={face.email}
                                isInvalid={face.emailErr}
                                errMsg={face.emailErr}
                                onChange={(e) => this.onChange(e, faceId)}
                              />
                              <Button
                                className="inline-btn"
                                onClick={(e) => this.sendEmail(e, faceId)}
                              >
                                <div className={`${!face.emailStatus}`}>
                                  Send email
                                </div>
                                <Spinner
                                  className={`${
                                    face.emailStatus === 'sending'
                                  }`}
                                  animation="border"
                                  role="status"
                                />
                                <BsCheckLg
                                  className={`check-icon ${
                                    face.emailStatus === 'sent'
                                  }`}
                                  size="2em"
                                />
                                <div
                                  className={`${face.emailStatus === 'resend'}`}
                                >
                                  Resend email
                                </div>
                              </Button>
                            </fieldset>
                          </Form>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Hub;
