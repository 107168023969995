import React from 'react';

import { Spinner, Button, Form } from 'react-bootstrap';
import { BsChevronRight, BsCheckLg } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { goTo, validateEmail, sendEmail } from '../../Utils.js';
import ContentHeader from '../../components/contentHeader';
import EmailField from '../../components/emailField';

import { checkEmailAvailability } from '../../services/api/auth';

import './onboarding.css';

class EnterEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: sessionStorage.getItem('email') || '',
      emailStatus: null,
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.unlockEmail = this.unlockEmail.bind(this);
    this.sendVerificationEmail = this.sendVerificationEmail.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {},
      emailStatus: null,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const { email, errors } = this.state;

    let emailErr = validateEmail(email);
    if (emailErr) {
      errors.emailErr = emailErr;
      this.setState({ errors });
    } else {
      this.setState({ emailStatus: 'loading' });

      // checks email availability
      checkEmailAvailability(email)
        .then((availability) => {
          sessionStorage.setItem('email', email);
          this.setState({ emailStatus: availability });
        })
        .catch((err) => {
          toast.error('Something went wrong. Try again later');
          this.setState({ emailStatus: null });
        });
    }
  }

  unlockEmail() {
    this.setState({ emailStatus: null });
  }

  sendVerificationEmail() {
    sendEmail(this.state.email, 'verification');
    goTo('/email-verification');
  }

  renderEmailAvContent() {
    const { email, emailStatus } = this.state;

    switch (emailStatus) {
      case 'available':
        return (
          <div className="pink-bg-box">
            <h4>Looks like you don't have an account yet</h4>
            <div>First, we need to verify your email address</div>
            <div className="btn-container">
              <Button variant="secondary" onClick={this.unlockEmail}>
                Use different email
              </Button>

              <Button onClick={this.sendVerificationEmail}>Continue</Button>
            </div>
            <div className="note">
              By clicking 'Continue' you give consent to send email to{' '}
              <b>{email}</b>.
            </div>
          </div>
        );
      case 'existing':
        return (
          <div className="pink-bg-box">
            <h4>You are already a part of CrowdClip team!</h4>
            <div>Log in to your account</div>
            <div className="btn-container">
              <Button variant="secondary" onClick={this.unlockEmail}>
                Use different email
              </Button>
              <Button onClick={() => goTo('/login')}>Log In</Button>
            </div>
          </div>
        );
      case 'unavailable':
        return (
          <div className="pink-bg-box">
            <h4>Sorry, this email address is unavailable</h4>
            <div>Contact us for assistance or try different email address</div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { email, emailStatus, errors } = this.state;

    let allowEmailEnter = !['available', 'existing', 'loading'].includes(
      emailStatus,
    );

    return (
      <div className="page-container">
        <ContentHeader
          title="Welcome to CrowdClip's video builder"
          subtitle="Let's get started! First, please enter your email."
        />

        <div className="page-content">
          <Form className="inline-form" noValidate onSubmit={this.onSubmit}>
            <fieldset disabled={!allowEmailEnter}>
              <EmailField
                value={email}
                isInvalid={errors?.emailErr}
                errMsg={errors?.emailErr}
                onChange={(e) => this.onChange(e)}
              />

              <Button className="inline-btn" type="submit">
                <div className={`text-with-icon big-icon ${allowEmailEnter}`}>
                  Continue <BsChevronRight className="chevron-icon" />
                </div>
                <Spinner
                  className={`${['loading'].includes(emailStatus)}`}
                  animation="border"
                  role="status"
                />
                <BsCheckLg
                  className={`check-icon ${['available', 'existing'].includes(
                    emailStatus,
                  )}`}
                  size="2em"
                />
              </Button>
            </fieldset>
          </Form>

          {this.renderEmailAvContent()}
        </div>
      </div>
    );
  }
}

export default EnterEmail;
