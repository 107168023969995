import React from 'react';
import { Spinner, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { goTo, validateEmail, sendEmail } from '../../Utils.js';
import { isEmpty } from '../../Utils.js';
import ContentHeader from '../../components/contentHeader';
import EmailField from '../../components/emailField';
import PasswordField from '../../components/passwordField';

import './onboarding.css';
import { signIn } from '../../services/api/sessions';
import { getUserWorkspaces } from '../../services/api/workspaces';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: sessionStorage.getItem('email') || '',
      password: '',
      isKeepSignIn: false,
      forgotPwEmail: '',
      errors: {},
      showResetPwModal: false,
      isLoading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.onSubmitForgotPw = this.onSubmitForgotPw.bind(this);
  }

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get('forgotPassword')) {
      this.setState({ showResetPwModal: true });
      window.history.pushState(null, null, '/login');
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value, errors: {} });
  }

  onCheckChange() {
    this.setState({ isKeepSignIn: !this.state.isKeepSignIn });
  }

  async onSubmit(e) {
    e.preventDefault();

    const { email, password, isKeepSignIn, errors } = this.state;

    let emailErr = validateEmail(email);
    if (emailErr) errors.emailErr = emailErr;

    if (password.trim().length === 0)
      errors.passwordErr = 'Password is required';

    if (Object.keys(errors).length === 0) {
      this.setState({ isLoading: true });

      signIn(email, password, isKeepSignIn)
        .then(async (user) => {
          const userWorkspaces = await getUserWorkspaces(user.session.userId);

          if (isEmpty(userWorkspaces)) goTo('/workspace-details');
          else {
            let workspace =
              userWorkspaces.find((workspace) => workspace.type === 1) ||
              userWorkspaces[0];
            localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
            goTo('/video-upload');
          }

          this.setState({ isLoading: false });
        })
        .catch((err) => {
          if (err.errMsg) errors.emailPwErr = err.errMsg;
          else toast.error('Something went wrong. Try again later');

          this.setState({ errors, isLoading: false });
        });
    } else {
      this.setState({ errors });
    }
  }

  onSubmitForgotPw(e) {
    e.preventDefault();

    const { forgotPwEmail, errors } = this.state;

    let emailErr = validateEmail(forgotPwEmail);
    if (emailErr) {
      errors.forgotPwEmailErr = emailErr;
      this.setState({ errors });
    } else {
      sendEmail(forgotPwEmail, 'password-reset');
      goTo('/forgot-password');
    }
  }

  render() {
    const {
      email,
      password,
      forgotPwEmail,
      isKeepSignIn,
      errors,
      isLoading,
      showResetPwModal,
    } = this.state;

    return (
      <div className="page-container">
        <ContentHeader
          title="Continue building your video"
          subtitle="Log in to continue with your CrowdClip video project."
        />

        <div className="page-content">
          <Form noValidate onSubmit={this.onSubmit}>
            <fieldset disabled={isLoading}>
              <EmailField
                value={email}
                isInvalid={errors?.emailErr || errors?.emailPwErr}
                errMsg={errors?.emailErr}
                onChange={(e) => this.onChange(e)}
              />

              {/* Password and error feedback are in group to avoid gap between them */}
              <Form.Group>
                <PasswordField
                  label="Password"
                  name="password"
                  value={password}
                  isInvalid={errors?.passwordErr || errors?.emailPwErr}
                  errMsg={errors?.passwordErr}
                  hideText={true}
                  onChange={(e) => this.onChange(e)}
                />
                <Form.Control.Feedback
                  className={errors?.emailPwErr ? 'show' : ''}
                  type="invalid"
                >
                  {errors?.emailPwErr}
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Check id="check-api-checkbox">
                    <Form.Check.Input
                      value={isKeepSignIn}
                      onChange={this.onCheckChange}
                    />
                    <Form.Check.Label>Keep me logged in</Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      this.setState({
                        showResetPwModal: true,
                        forgotPwEmail: email,
                        errors: {},
                      })
                    }
                    className="password-link"
                  >
                    Forgot password?
                  </Link>
                </Col>
              </Row>

              <Button className="submit-loading-btn" type="submit">
                <div className={`${!isLoading}`}>Log In</div>
                <Spinner
                  className={`${isLoading}`}
                  animation="border"
                  role="status"
                />
              </Button>
            </fieldset>
          </Form>

          <div>
            Don't have an account? <Link to="/enter-email">Sign up</Link>
          </div>
        </div>

        <Modal
          className="custom-modal"
          show={showResetPwModal}
          size="lg"
          onHide={() => this.setState({ showResetPwModal: false })}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot your password?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Enter your email address and we will send you instructions to
              reset your password.
            </div>
            <Form noValidate onSubmit={this.onSubmitForgotPw}>
              <EmailField
                name="forgotPwEmail"
                value={forgotPwEmail}
                isInvalid={errors?.forgotPwEmailErr}
                errMsg={errors?.forgotPwEmailErr}
                onChange={(e) => this.onChange(e)}
              />
              <div className="note">
                By clicking 'Continue' you give consent to send email to the
                provided email address.
              </div>
              <Button type="submit">Continue</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Login;
