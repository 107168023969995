import React from 'react';

const FacesSummary = ({ face, handleClickImg }) => {
  const handleFaceClick = () => {
    // Call the handleClickImg function when a face is clicked
    handleClickImg(face);
  };

  return (
    <div className="face-block" onClick={handleFaceClick}>
      <img
        src={face.img}
        className="face-icon face-preview"
        alt={`${face.name} Icon`}
      />
      <div className="face-name">{face.name}</div>
    </div>
  );
};

export default FacesSummary;
