import React from 'react';
import parse from 'html-react-parser';
import { BsFillCloudArrowDownFill, BsInfo } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import PopoverTrigger from './popoverTrigger';

const ContentHeader = (props) => {
  const { title, subtitle, noIcons, onZipClick } = props;

  const renderIconsContainer = () => {
    return (
      <div className="circle-icons-container">
        {onZipClick && (
          <PopoverTrigger
            content={
              <div onClick={onZipClick} className="circle-icon clickable">
                <BsFillCloudArrowDownFill />
              </div>
            }
            placement="bottom"
            popoverHeader="Don't know what to upload?"
            popoverBody="Click the icon to download ZIP file with sample data you can use"
          />
        )}

        <PopoverTrigger
          content={
            <Link
              to="/help"
              target="_blank"
              className="circle-icon big clickable"
            >
              <BsInfo />
            </Link>
          }
          placement="bottom"
          popoverHeader="Need help?"
          popoverBody="Click the icon to open Help page"
        />
      </div>
    );
  };

  return (
    <div className="content-header">
      <h1 className="heading">
        {parse(title)}
        {!noIcons && renderIconsContainer()}
      </h1>

      <div>{parse(subtitle)}</div>
    </div>
  );
};

export default ContentHeader;
