import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { goTo, countSecsFromNow, sendEmail } from '../../Utils';
import passwordImg from '../../assets/password.png';
import { RESEND_EMAIL_IN_SEC } from '../../constants';

import './onboarding.css';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: sessionStorage.getItem('email') || '',
      emailSend: {},
      resendEmailIn: RESEND_EMAIL_IN_SEC,
    };

    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    const { email } = this.state;

    let emailSends = JSON.parse(localStorage.getItem('emailSends'));
    let emailSend = emailSends?.filter(
      (eSend) => eSend.email === email && eSend.type === 'password-reset',
    )[0];

    if (!emailSend) goTo('/');
    else
      this.setState({
        emailSend,
        resendEmailIn: countSecsFromNow(emailSend.resendMoment),
      });
  }

  resendEmail() {
    const { emailSend } = sendEmail(this.state.email, 'password-reset');
    this.setState({ emailSend, resendEmailIn: RESEND_EMAIL_IN_SEC });
    toast.success('Reset password email is resent');
  }

  render() {
    const { email, resendEmailIn, emailSend } = this.state;

    if (emailSend?.resendMoment && resendEmailIn > 0) {
      setTimeout(() => {
        this.setState({
          resendEmailIn: countSecsFromNow(emailSend.resendMoment),
        });
      }, 1000);
    }

    // to get link that will be send
    let link = '';
    if (emailSend)
      link = window.location.origin + '/reset-password?id=' + emailSend.id;

    return (
      <div className="page-container email-confirmation">
        <div className="pink-bg-box">
          <img src={passwordImg} alt="Password Graphic" />
          <h4>Check your email</h4>
          <div>
            Click on the reset password link sent to <b>{email}</b>. Be sure to
            check your spam if it doesn't arrive in your inbox!
          </div>
          <div className="resend-container">
            <div>Still can't find the email?</div>
            <Button onClick={this.resendEmail} disabled={resendEmailIn > 0}>
              Resend email
            </Button>
            <div className="note">
              {resendEmailIn > 0
                ? `You will be able to resend the email in ${resendEmailIn} seconds.`
                : 'You are able to resend the email now.'}
            </div>
          </div>
          <div>
            Entered incorrect email address?{' '}
            <Link to="/login?forgotPassword=true">Go back</Link>
          </div>
        </div>

        <div style={{ marginTop: '1vw', zIndex: '10' }}>
          Link sent in email: <Link to={link}>{link}</Link>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
