import { v4 as uuidv4 } from 'uuid';

import { privateFileCreate, publicFileCreate } from './api/files';
import {
  privateUpload,
  publicUpload,
  publicUploadWithInfo,
  privateUploadWithInfo,
} from './api/upload';

export const uploadFile = async ({
  file,
  workspaceId,
  fileKind = null,
  parentElementId = null,
  isPublic = false,
}) => {
  const formData = new FormData();

  const uploadRequestId = uuidv4();
  formData.append('uploadRequestId', uploadRequestId);

  const files = [];

  files.push({
    name: file.name,
    workspaceId: workspaceId,
    fileKind: fileKind,
    uploadSessionId: uuidv4(),
    uploadRequestId: uploadRequestId,
    size: file.size,
    parentElementId: parentElementId,
  });

  formData.append('files', file);

  if (isPublic) {
    await publicFileCreate(files);
    await publicUpload(formData);
  } else {
    await privateFileCreate(files);
    await privateUpload(formData);
  }
};

// Note: the below version upload function is used when there is "info" variable passed in
// And also need promise to be able to used in videoUpload and UploadFaces page.
export const uploadFileWithInfo = ({
  file,
  workspaceId,
  parentElementId = null,
  info,
  fileKind = null,
  isPublic = false,
}) => {
  return new Promise((resolve, reject) => {
    const uploadRequestId = uuidv4();
    const formData = new FormData();
    formData.append('uploadRequestId', uploadRequestId);
    let files = [];

    files.push({
      name: file.name,
      workspaceId: workspaceId,
      uploadSessionId: uuidv4(),
      fileKind: fileKind,
      uploadRequestId: uploadRequestId,
      parentElementId: parentElementId,
      size: file.size,
    });

    formData.append('files', file);

    if (isPublic) {
      publicFileCreate(files)
        .then(() => {
          publicUploadWithInfo(formData, info)
            .then(() => resolve())
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    } else {
      privateFileCreate(files)
        .then(() => {
          privateUploadWithInfo(formData, info)
            .then(() => resolve())
            .catch((error) => reject(error));
        })
        .catch((error) => reject(error));
    }
  });
};
