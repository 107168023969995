import React, { useState } from 'react';

import { Button, Collapse } from 'react-bootstrap';
import {
  BsCheck2Square,
  BsCheckCircleFill,
  BsQuestionCircleFill,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

const UploadInfo = (props) => {
  const { isCollapse, type, infoPoints, heading, note, premiumMsg, addClass } =
    props;
  const user = JSON.parse(localStorage.getItem('user'));
  const infoOpenState = JSON.parse(localStorage.getItem('infoOpenState')) || {};

  // if info collapse was opened or wasn't iteracted with – set true
  const [infoOpen, setInfoOpen] = useState(
    [true, null, undefined].includes(infoOpenState[type]),
  );

  const onInfoCollapse = () => {
    setInfoOpen(!infoOpen);

    localStorage.setItem(
      'infoOpenState',
      JSON.stringify({ ...infoOpenState, [type]: !infoOpen }),
    );
  };

  return (
    <div className={`page-block ${addClass || ''}`}>
      {isCollapse ? (
        <React.Fragment>
          <Collapse in={infoOpen}>
            <div id="collapse-wrapper">
              <div className="page-block upload-info">
                <h5>{heading}</h5>

                {infoPoints.map((point) => {
                  return (
                    <div className="text-with-icon big-icon">
                      <BsCheck2Square />
                      <div>{point}</div>
                    </div>
                  );
                })}

                <div className="note">{note}</div>

                <Button
                  className="collapse-btn"
                  onClick={onInfoCollapse}
                  aria-controls="collapse-wrapper"
                  aria-expanded={infoOpen}
                >
                  <BsCheckCircleFill />
                  <div>I understood</div>
                </Button>
              </div>
            </div>
          </Collapse>

          {!infoOpen && (
            <Button
              className="collapse-btn"
              onClick={onInfoCollapse}
              aria-controls="collapse-wrapper"
              aria-expanded={infoOpen}
            >
              <BsQuestionCircleFill />
              <div>What files can I upload?</div>
            </Button>
          )}
        </React.Fragment>
      ) : (
        <div className="page-block upload-info">
          <h5>{heading}</h5>

          {infoPoints.map((point) => {
            return (
              <div className="text-with-icon big-icon">
                <BsCheck2Square />
                <div>{point}</div>
              </div>
            );
          })}

          <div className="note">{note}</div>
        </div>
      )}

      {premiumMsg && user?.userType === 'freemium' && (
        <div className="note bold">
          Switch to{' '}
          <Link to="/pricing#subscription" target="_blank">
            Premium
          </Link>{' '}
          {premiumMsg}
        </div>
      )}
    </div>
  );
};

export default UploadInfo;
