import personImg from './assets/person.png';
import config from './config';

import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  RESEND_EMAIL_IN_SEC,
  LINK_EXPIRE_IN_MIN,
} from './constants';

const accessToken = localStorage.getItem('accessToken');

const currentWorkspace = JSON.parse(localStorage.getItem('currentWorkspace'));
export const workspaceId = currentWorkspace?.workspaceId || config.workspaceId;

export const accessTokenHeader = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  },
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const goTo = (link) => {
  window.location = link;
};

export const isEmpty = (value) => {
  return value == null || value.length === 0 || Object.keys(value).length === 0;
};

export const isPlural = (array) => {
  return array.length > 1 ? 's' : '';
};

// TODO replace with uuidv4
export const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
};

export const validateEmail = (email) => {
  if (!emailRegex.test(email))
    return email.trim().length === 0
      ? 'Email address is required'
      : 'Invalid email address';

  return null;
};

export const validatePassword = (password, confirmPassword) => {
  let passwordErr = null;
  let confirmPasswordErr = null;

  let passwordTrim = password.trim();

  if (passwordTrim.length === 0) passwordErr = 'Password is required';
  else if (
    passwordTrim.length < PASSWORD_MIN_LENGTH ||
    passwordTrim.length > PASSWORD_MAX_LENGTH
  )
    passwordErr = `Password must be ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters long`;
  else if (!/[a-z]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one lowercase character';
  else if (!/[A-Z]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one uppercase character';
  else if (!/[0-9]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one number';
  else if (!/[`~!@#$%^&*()_+\-={}|[\]\\;':",./<>?]/.test(passwordTrim))
    passwordErr = 'Password must contain at least one special character';

  if (confirmPassword.trim().length === 0)
    confirmPasswordErr = 'Confirm password is required';
  else if (password !== confirmPassword)
    confirmPasswordErr = 'Passwords must match';

  return { passwordErr, confirmPasswordErr };
};

export const countSecsFromNow = (to) => {
  let now = new Date();
  let then = new Date(to);

  return Math.round((then - now) / 1000);
};

export const sendEmail = (email, type) => {
  sessionStorage.setItem('email', email);

  let emailSends = JSON.parse(localStorage.getItem('emailSends')) || [];

  let resendMoment = new Date();
  resendMoment = new Date(
    resendMoment.setSeconds(new Date().getSeconds() + RESEND_EMAIL_IN_SEC),
  );

  let expiryMoment = new Date();
  expiryMoment = new Date(
    expiryMoment.setSeconds(new Date().getSeconds() + LINK_EXPIRE_IN_MIN * 60),
  );

  let emailSend = {
    id: guidGenerator(),
    email,
    type,
    resendMoment,
    expiryMoment,
  };

  // email sending will happen here

  // adds new emailSend or updates if existing (of same email and type)
  let foundEmailSend = emailSends.filter(
    (eSend) => eSend.email === email && eSend.type === type,
  )[0];
  if (!foundEmailSend) emailSends.push(emailSend);
  else emailSends[emailSends.indexOf(foundEmailSend)] = emailSend;

  localStorage.setItem('emailSends', JSON.stringify(emailSends));

  return { emailSend };
};

export const getFileImg = (file) => {
  return file
    ? `https://s3.ap-southeast-2.amazonaws.com/crowdclip.au.files/${
        file.elementId
      }/${file.name.replace(/\s+/g, '+')}`
    : personImg;
};

// convert uploaded image to base64 for preview
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    if (callback && typeof callback === 'function') {
      callback(reader.result);
    }
  });
  reader.readAsDataURL(img);
};
